import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";
import { ThemeVariable } from "@/theme";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
    theme?: ThemeVariable;
}

function ThemeIcon(props: IconProps) {
    const { theme, ...attrs } = props;
    const [color] = useThemeColor(theme);

    return <span role="img" { ...attrs }>
<svg viewBox="0 0 14 14" fill="none" color={ color }>
    <path d="M5.25 10.5L8.75 7L5.25 3.5" stroke="currentColor"
          strokeWidth="2" strokeLinecap="round"
          strokeLinejoin="round"/>
</svg>
    </span>;
}

export default memo(ThemeIcon);

