import FloatModal from "@/components/FloatModal";
import { useAppDispatch, useAppSelector } from "@/store";
import { closeHistory, EventResult, ForecastDataItem } from "@/store/slice/prediction";
import { getPredictHistory } from "@/apis/im";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HistoryList from "@/pages/Chat/feature/Modal/PredictionModal/HistoryList";
import HistoryDetail from "@/pages/Chat/feature/Modal/PredictionModal/HistoryDetail";
import { parseStr } from "@chat/shared";
import { useRequest } from "ahooks";

export type HistoryData = {
    hitCount: number;
    predictCount: number;
    predictRate: number;
    list: Array<{
        forecastResults: string;
        gameIcon: string;
        gameName: string;
        id: number;
        issueNo: number;
        packageId: number;
        status: number;
        updateTime: number;
        user: {
            avatar: string;
            nickname: string;
        };
        win: number;
    }>
}

enum ModalType {
    List,
    Detail
}

function HistoryModal() {
    const dispatch = useAppDispatch();
    const { historyVisible, forecastData } = useAppSelector(state => state.prediction);
    const { conversationID } = useParams();
    const [historyData, setHistoryData] = useState({
        hitCount: 0,
        predictCount: 0,
        predictRate: 0,
        list: [] as any[],
    });
    const [modalType, setModalType] = useState(ModalType.List);
    const [historyDetail, setHistoryDetail] = useState({
        forecastResults: {} as EventResult,
        winningResults: {} as EventResult,
        forecastData: {} as ForecastDataItem
    } as any);

    const handlerRender = () => {
        return <div className="followedModal-handler">
            <div className="followed-button" style={ { width: "100%" } }
                 onClick={ () => dispatch(closeHistory()) }>Confirm
            </div>
        </div>;
    };
    const getEmptyData = () => {
        return {
            hitCount: 0,
            predictCount: 0,
            predictRate: 0,
            list: [] as any[],
        };
    };
    const { run: fetchPredictHistory, loading } = useRequest(getPredictHistory, {
        manual:true,
        onSuccess(res) {
            if (res.code === 0) {
                setHistoryData(res.data ? res.data : getEmptyData());
            } else {
                setHistoryData(getEmptyData());
            }
        },
        onFinally() {
        }
    });
    useEffect(() => {
        if (historyVisible) {
            setModalType(ModalType.List);
            fetchPredictHistory({
                issueNo: forecastData.issueNo,
                groupId: conversationID!,
                gameType: forecastData.gameType
            });
        } else {
            setHistoryData(getEmptyData());
        }
    }, [historyVisible]);
    const handleShowDetail = (data: any) => {
        setHistoryDetail({
            ...data,
            winningResults: parseStr(data.winningResults),
            forecastResults: parseStr(data.forecastResults),
            forecastData: {
                ...data,
                gameName: forecastData.gameName,
                forecastResults: parseStr(data.winningResults)
            }
        });
        setModalType(ModalType.Detail);
        // 初始化详情
        // 1.预测内容
        // 2.结果
    };
    return <FloatModal
        visible={ historyVisible }
        backClick={ modalType === ModalType.Detail ? () => {
            setModalType(ModalType.List);
        } : null }
        onClose={ () => {
            dispatch(closeHistory());
        } }
        handler={ handlerRender() }
        title="forecast results">
        {
            modalType === ModalType.List ?
                <HistoryList forecastData={ forecastData }
                             historyData={ historyData }
                             loading={ loading }
                             onClick={ handleShowDetail }/> : <HistoryDetail detail={ historyDetail }/>
        }

    </FloatModal>;
}

export default HistoryModal;
