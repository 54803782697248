import { FloatingPanelRef, Mask } from "antd-mobile";
import { FloatingPanel } from "./floating-panel";
import React, { ReactElement, ReactNode, useEffect, useRef } from "react";
import classNames from "classnames";
import { Back, Close } from "@/components/themeSvg";
// 约定，底部的handler操作，不要写自适应
type FloatModalProps = {
    title?: ReactElement | string;
    backClick: (() => void) | null;
    handler?: ReactElement;
    children?: ReactNode;
    contentClass?: string;
    visible: boolean;
    onClose?: (val: boolean) => void
}
const config = {
    headerHeight: 28,
    titleHeight: 58,
    handlerHeight: 64,
    anchors: [0, 524, window.innerHeight]
};

function FloatModal(props: FloatModalProps) {
    const { visible, title, handler, onClose, children, contentClass, backClick } = props;
    const floatRef = useRef<FloatingPanelRef>(null);
    // 动态控制内容高度
    const onHeightChange = (height: number, animating: boolean) => {
        if (height === 0 && !animating) {
            onClose?.(false);
        }
    };
    const setFloatHeight = () => {
        floatRef.current?.setHeight(config.anchors[1], { immediate: false });
    };
    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (visible) {
            timer = setTimeout(() => {
                if (!floatRef.current) {
                    timer && clearTimeout(timer);
                    timer = setTimeout(setFloatHeight, 100);
                    return;
                } else {
                    setFloatHeight();
                }
            }, 50);
        } else {
            floatRef.current?.setHeight(0);
        }
        return () => {
            timer && clearTimeout(timer);
        };
    }, [visible]);
    return <Mask
        visible={ visible }
        className="my-floatModal"
        onMaskClick={ () => onClose?.(false) }
        getContainer={ () => document.body }
        opacity={ 0.37 }
        destroyOnClose={ true }
    >
        <FloatingPanel
            ref={ floatRef }
            fixed
            className={ classNames("my-floatModal-panel") }
            onHeightChange={ onHeightChange }
            anchors={ config.anchors }
            handleDraggingOfContent={ false }
            customHeader={
                title ? <div className="floatModal-header">
                    {
                        backClick ? <Back className="close" onClick={ backClick }></Back> :
                            <Close className="close" onClick={ () => onClose?.(false) } theme="--whats-color-t1"/>
                    }

                    <div className="title">{ title }</div>
                </div> : null
            }
        >
            <div className={ classNames("floatModal-content", "custom-scroll", contentClass) }>
                {
                    children
                }
            </div>
            {
                handler ? <div className="floatModal-handler">
                    { handler }
                </div> : null
            }
        </FloatingPanel>
    </Mask>;
}

export default FloatModal;
