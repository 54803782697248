import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";
import { ThemeVariable } from "@/theme";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
    theme?: ThemeVariable;
}

function ThemeIcon(props: IconProps) {
    const { theme, ...attrs } = props;
    const [color] = useThemeColor(theme);

    return <span role="img" { ...attrs }>
<svg viewBox="0 0 25 25" fill="none" color={ color }>
    <path
        d="M13.8709 3.63807C11.5257 4.24952 9.79463 6.38203 9.79463 8.91887C9.79463 11.9327 12.2377 14.3758 15.2515 14.3758C17.7884 14.3758 19.9209 12.6447 20.5323 10.2995C20.6478 10.8606 20.7085 11.4418 20.7085 12.0371C20.7085 16.7731 16.8693 20.6123 12.1333 20.6123C7.39735 20.6123 3.55811 16.7731 3.55811 12.0371C3.55811 7.30116 7.39735 3.46191 12.1333 3.46191C12.7286 3.46191 13.3098 3.52258 13.8709 3.63807Z"
        stroke="currentColor" strokeWidth="2" strokeLinejoin="round"/>
</svg>
    </span>;
}

export default memo(ThemeIcon);
