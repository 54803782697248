import { ZIMCustomMessage } from "@chat/zim-sdk";
import { Reflex } from "@chat/component";
import MessageState from "@/pages/Chat/feature/MessageItem/MessageState";
import { getVmSize, parseStr } from "@chat/shared";
import { useMemo } from "react";
import { Space } from "antd-mobile";
import classNames from "classnames";

type CardMessageProps = {
    data: ZIMCustomMessage
}
type ContentItem = {
    amount: number,
    level: number,
    count: number,
    index: string,
    number: string
}
type BetItem = {
    gameType: "quick3d",
    groupId: string,
    packageId: number,
    gameCode: string,
    issueNo: string,
    content: ContentItem | ContentItem[],
    wonAmount: number,
    subMsgType: number,
    imUserId: string,
    wonPlayerName?: string,
    wonPlayerId?: string,
    chatType: "1" | "2"  // 1 x下注 2 结算
}
export const getBetData = (json: string) => {
    const outData = parseStr<any>(json);
    outData.content = parseStr<ContentItem | ContentItem[]>(outData.content);
    return outData as BetItem;
};

function BetAlertMessage(props: CardMessageProps) {
    const { data } = props;
    const alertData = useMemo(() => {
        return getBetData(data.message);
    }, [data.message]);
    const renderPrice = () => {

        if (+alertData.chatType === 1) {
            return <span style={ { color: "#EF8102" } }>{ "₹" + (alertData.content as ContentItem).amount }</span>;
        }
        return <span style={ { color: "#F52D2D" } }>{ "₹" + alertData.wonAmount }</span>;
    };
    const renderDigit = (content: ContentItem) => {
        const type = ["-", "-", "-"];
        const reflexObj: any = {
            "A": 0,
            "B": 1,
            "C": 2,
        };
        content.index.split("").forEach((item, index) => {
            type[reflexObj[item]] = (content.number).toString().split("")[index];
        });
        return type.join("");
    };
    const renderDigitList = (content: ContentItem | ContentItem[]) => {
        if (!Array.isArray(content)) {
            content = [content];
        }

        return <Space direction="vertical">
            {
                content.slice(0, 3).map((item, index) => {
                    return <Reflex.Digit type={ renderDigit(item) } key={ index }/>;
                })
            }
        </Space>;

    };
    return <div className={ classNames(+alertData.chatType === 2 && "wonType", "clearfix") }>
        <Space align={ Array.isArray(alertData.content) ? "start" : "center" }>
            <div style={ {
                display: "flex",
                height: Array.isArray(alertData.content) ? getVmSize(24) : "auto",
                alignItems: "center",
                gap: getVmSize(4),
                fontSize: getVmSize(14),
                fontWeight: 500,
                color: "#3B3B3B"
            } }>
                <div>
                    {
                        +alertData.chatType === 2 ? <span>
                            <span style={ {
                                color: "#1DAB61",
                                marginRight: getVmSize(4)
                            } }>{ alertData.wonPlayerName!.length > 10 ? alertData.wonPlayerName?.slice(0, 10) + "..." : alertData.wonPlayerName }</span>
                            <span>Won</span>
                        </span> : "Bet"
                    }
                </div>
                <div style={ { fontSize: getVmSize(14), fontWeight:700 } }>
                    {
                        renderPrice()
                    }
                </div>
                <div>in</div>
                { +alertData.chatType === 2 && <div>{alertData.gameCode?.split("-")[1]}</div> }
            </div>
            {
                +alertData.chatType === 1 && renderDigitList(alertData.content)
            }
        </Space>
        <MessageState style={ { marginTop: getVmSize(3) } } className="message-state__right"
                      data={ data }></MessageState>
    </div>;
}

export default BetAlertMessage;
