import { createHttp, TOKEN_TYPE, type TypeResponse } from "./http";

const http = createHttp(process.env.REACT_APP_IM_API_URL!, TOKEN_TYPE.ORIGIN);
const apis = {
    getReqParams: "/zgIm/getReqParams", // 获取imtoken [ x ]
    // 获取客服
    getCustomer: "/zgIm/customers",
    // 分页获取gif
    getGifPage: "/zgIm/getImEmoticonsPage", // [ x ]
    // 获取当前期前多个历史记录
    getPredictHistory: "/game/predict/last/list",
    // 发送消息，推送 ！只有单聊才调用 给app推送消息
    sendFbMessage: "/groupOperate/fb/sendUserMessage",
};
type ImTokenRes = {
    userID: string;
    token: string;
    reuse?: boolean;
}
// 获取imtoken
export const getImToken = (data: {deviceCode?: string, fbToken?: string}) => {
    const { deviceCode, fbToken } = data;
    const headers: Record<string, any> = {
    }
    if (deviceCode) {
        headers.deviceCode = deviceCode;
    }
    if (fbToken) {
        headers.fbToken = fbToken;
    }
    return http.post<any, TypeResponse<ImTokenRes>>(apis.getReqParams, null, {
        headers
    });
};
export type IdentityItem = {
    imCustomer: "1" | "2";
    imPermanent: "0" | "1" | "2"; // 0不是常驻 1登陆常驻 2匿名常驻
    imUserId: string;
    imUserStatus: string;
    userAvatar: string;
    userId: number;
    userName: string;
}

export const getCustomer = () => {
    // imCustomer 可选 1支付客服 2游戏客服
    return http.get<any, TypeResponse<IdentityItem[]>>(apis.getCustomer);
};

// 获取gif图片
export const getGifPage = (params: { pageNo: number, pageSize: number }) => {
    return http.post<any, TypeResponse<any>>(apis.getGifPage, params);
};
export const getPredictHistory = (params: { issueNo: number; groupId: string; gameType: string }) => {
    return http.post<any, TypeResponse<any>>(apis.getPredictHistory, params);
};

export const sendFbMessage = (params: {
    title: string;
    body: string;
    imageUrl: string;
    imUserId: string;
}) => {
    return http.post<any, TypeResponse<any>>(apis.sendFbMessage, params);
};
