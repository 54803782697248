import React from 'react';
import { Outlet } from "react-router-dom";
import { Button, ErrorBlock, Space } from "antd-mobile";
import { useAppDispatch, useAppSelector } from "@/store";
import { EventType, useListenMessage } from "@/utils/sendPostMessage";
import { UndoOutline } from "antd-mobile-icons";
import { useZimInit } from "@/hooks/useZimInit";
import { fetchConversationList } from "@/store/slice/home";
import { isVisitorUser } from "@/utils";

function Content() {
    const { errPage, mounted } = useZimInit();
    const dispatch = useAppDispatch();
    const { theme } = useAppSelector(state => state.common);

    useListenMessage((payload) => {
        if (isVisitorUser()) {
            return;
        }
        if ([EventType.NATIVE_ROUTE_CHANGE].includes(payload.event)) {
            dispatch(fetchConversationList());
        }
    });

    const renderPage = () => {
        if (errPage) {
            return <ErrorBlock fullPage>
                <Button size="small" style={ { "--background-color": theme.value["--whats-base-color"] } }
                        color="success" onClick={ () => window.location.reload() }>
                    <Space>
                        <UndoOutline/>
                        Reload
                    </Space>
                </Button>
            </ErrorBlock>;
        }
        return mounted && <Outlet></Outlet>;
    };
    return (
        <section className="layout-content">
            {
                renderPage()
            }
        </section>
    );
}

export default Content;
