import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "@/utils";
import { isShowTime } from "@chat/shared";
import _ from "lodash";
import { getBalanceList, getPayChannelList } from "@/apis/app";
import {
    addMergeState,
    ChatMessageItem,
    ConversationType,
    createTimeMessage,
    deleteMessages,
    getConversationType,
    getMessageList,
    GroupMemberRole,
    IUserInfo,
    MessageReceiptStatus,
    ZIMConversation,
    ZIMGroupMemberInfo,
    ZIMImageMessage,
    ZIMMessage,
    ZIMVideoMessage,
} from "@chat/zim-sdk";
import { HistoryItem, RoomItem } from "@/apis/room";

type ChannelItem = {
    id: number;
    maxAmount: number;
    minAmount: number;
    payIcon: string;
    payName: string;
    payTag: string
}
type BalanceItem = {
    balance: number;
    giveBalance: number;
    giveScale: number;
    id: number;
    money: number;
    ratio: number;
    sort: number;
    status: number
}
export type GroupMemberItem = {
    userID: string;
    muteExpiredTime: number, // 禁言时间戳
    block: boolean, // 拉黑
    remark: string, // 备注
    blockId?: string;
    mutedId?: string;
    memberRole: GroupMemberRole
}

const initialState = {
    list: [] as ChatMessageItem[],
    loading: false,
    isEnd: false,
    showVoice: false, // 是否显示音频操作框
    currentVoiceClientMsgID: "", // 当前正在播放的id
    conversationDetail: {} as ZIMConversation & { notExist: boolean },
    notReadNum: 0, // 消息未读数量
    // 当前窗口是否是可选状态
    isSelect: false,
    // 当前选中的列表
    selectList: [] as ChatMessageItem[],
    // 当前引用的消息
    quoteMessage: null as ChatMessageItem | null,
    // 当前群信息
    groupInfo: null as RoomItem | null,
    groupOwnerList: [] as ZIMGroupMemberInfo[],
    groupGameNoticeList: [] as HistoryItem[],
    singleInfo: {} as IUserInfo,
    noticeBarVisible: false, // 公告框
    noticeBarModalVisible: false, // 公告框详细
    ruleModalVisible: false, // 规则弹框
    settingVisible: false,
    historyVisible: false,
    selfInfo: {} as GroupMemberItem, // 当前用户在群里的信息
    // 当前群的管理员列表
    adminList: [] as ZIMGroupMemberInfo[],
    chatMessage: "",
    showFace: false,
    deleteMessageModalVisible: false,
    // 打开视频
    videoData: {
        visible: false,
        fileDownloadUrl: ""
    } as ZIMVideoMessage & { visible: boolean },
    rechargeVisible: false, // 充值界面
    rechargeBalanceList: [] as BalanceItem[],
    rechargeChannel: {} as ChannelItem,
    rechargeValue: ''
};

// 异步action。 获取历史会话记录， 必须判断是不是客服才行
export const fetchHistoryList = createAsyncThunk(
    "chat/fetchHistoryList",
    async (conversationID: string) => {
        try {
            const messages = await getMessageList(conversationID);
            // 筛选一些不需要的信息
            return {
                messageList: messages || []
            };
        } catch (err) {
            console.error(err, "err");
            return {
                messageList: []
            };
        }

    }
);
export const fetchRechargeData = createAsyncThunk(
    "chat/fetchRechargeData",
    async (_, { dispatch }) => {
        const IM_TAG = "IM_RECHARGE";
        // 获取im渠道
        getPayChannelList().then(res => {
            if (res.code === 0) {
                const imChannel = res.data.find((item: any) => item.payTag === IM_TAG);
                if (imChannel) {
                    dispatch(setRechargeChannel(imChannel));
                }
            }
        });
        // 获取充值列表
        getBalanceList().then(res => {
            if (res.code === 0) {
                const list = (res.data as BalanceItem[]).sort((a, b) => a.balance - b.balance);
                dispatch(setRechargeBalance(list));
                dispatch(setRechargeValue(list[0]?.balance + ""));
            }
        });
    }
);
export const deleteMessageList = createAsyncThunk("chat/deleteMessageList", async (payload: {
    messageList: ChatMessageItem[],
    conversationID: string,
    isAlsoDeleteServerMessage: boolean
}, { dispatch }) => {
    // 清除信息
    const { messageList, conversationID, isAlsoDeleteServerMessage } = payload;
    dispatch(deleteMessageFormStore(messageList));
    // 删除的时候需要判断是否是群聊，群聊不支持删除
    if (getConversationType(conversationID) !== ConversationType.Room) {
        return deleteMessages(messageList, conversationID, isAlsoDeleteServerMessage);
    }
});
const canPush = (list: ChatMessageItem[], message: ChatMessageItem) => {
    if (!message.messageID) return true;
    return !list.some(item => item.messageID === message.messageID);

};
// 聊天信息相关
export const chatSlice = createSlice({
    name: "chat" as const,
    initialState,
    reducers: {
        // 添加一条新数据
        addNewMessage(state, action) {
            const list = state.list;
            const prev = list[list.length - 1];
            // 防止重复
            if (canPush(list, action.payload)) {
                if (action.payload.messageID) {
                    const hasMessage = list.find(item => item.messageID === action.payload.messageID);
                    if (hasMessage) return;
                }
                const cur = action.payload as ZIMMessage;
                const addList: ChatMessageItem[] = [];
                const message = addMergeState(cur, list);
                const showTime = isShowTime(prev ? prev.timestamp : 0, cur.timestamp);
                if (showTime) {
                    const timeMessage: any = createTimeMessage(cur.timestamp);
                    addList.push(timeMessage);
                    message.isMergeMessage = false;
                }
                addList.push(message);
                state.list.push(...addList);
            }

        },
        setMessageList(state, action) {
            state.list = action.payload;
        },
        addHistoryMessage(state, action) {
            state.list.unshift(...action.payload);
        },
        // 更新列表数据
        updateMessage(state, action) {
            const index = state.list.findIndex(item => item.messageID === action.payload.messageID);
            const list = state.list;
            if (~index) {
                list[index] = {
                    ...list[index],
                    ...action.payload
                };
                state.list = list;
            }
        },
        updateMessageReceipt(state, action) {
            const curIndex = state.list.findIndex(item => item.messageID === action.payload.messageID);
            if (~curIndex) {
                state.list[curIndex] = {
                    ...state.list[curIndex],
                    receiptStatus: action.payload.status
                };
            }

        },
        // 主要在发送成功消息后，更新本地
        updateLocalMessage(state, action) {
            const index = state.list.findIndex(item => item.localMessageID === action.payload.localMessageID);
            const list = state.list;
            // 关于媒体信息，不需要进行的更新地址，因为更新本地url会产生闪烁
            const { thumbnailDownloadUrl, ...updateAttr } = action.payload as ZIMImageMessage;
            if (~index) {
                list[index] = {
                    ...list[index],
                    ...updateAttr
                };
                state.list = list;
            }
        },
        // 更新状态为已读
        updateMessageRead(state, action) {
            const list = state.list;
            const msgIDList = action.payload;
            msgIDList.forEach((id: string) => {
                const curIndex = list.findIndex(item => item.messageID === id);
                if (~curIndex) {
                    list[curIndex] = {
                        ...list[curIndex],
                        receiptStatus: MessageReceiptStatus.Done
                    };
                }
            });
        },
        // 设置当前的群信息
        updateGroupInfo(state, action: PayloadAction<RoomItem>) {
            if (action.payload) {
                state.groupInfo = action.payload;
                // 群公告
                state.noticeBarVisible = !!action.payload.groupNotice.trim();
            }
        },
        // 设置当前群历史游戏消息
        setGroupGameList(state, action) {
            state.groupGameNoticeList = action.payload;
        },
        // 设置当前群用户列表
        setGroupOwnerList(state, action) {
            state.groupOwnerList = action.payload;
        },
        // 更新当前单聊信息
        updateSingleInfo(state, action) {
            state.singleInfo = JSON.parse(JSON.stringify(action.payload));
        },
        // 设置选中状态
        setSelectState(state, action) {
            state.isSelect = action.payload;
            state.selectList = [];
        },
        // 添加选中的消息列表
        addSelectList(state, action) {
            // 判断是否存在的，存在就取消，不存在就添加
            const index = state.selectList.findIndex(item => item.messageID === action.payload.messageID);
            if (~index) {
                state.selectList.splice(index, 1);
                if (state.selectList.length === 0) {
                    state.isSelect = false;
                }
            } else {
                if (state.selectList.length === 5) {
                    toast("Select up to 5 items!", "error");
                    return;
                }
                state.selectList.push(action.payload);
            }

        },
        setQuoteMessage(state, action) {
            state.quoteMessage = action.payload;
        },
        setShowVoice(state, action) {
            state.showVoice = action.payload;
        },
        // 设置当前正在播放的id
        setCurrentVoiceId(state, action) {
            state.currentVoiceClientMsgID = action.payload;
        },
        // 清除信息
        deleteMessageFormStore(state, action) {
            // deleteMessages
            const clientMsgIDs = Array.isArray(action.payload) ? action.payload : [action.payload];
            const list = clientMsgIDs.map((item: any) => item.messageID);
            _.remove(state.list, item => list.includes(item.messageID));
        },
        setRuleModalVisible(state, action: { payload: boolean }) {
            state.ruleModalVisible = action.payload;
        },
        setNoticeBarModalVisible(state, action: { payload: boolean }) {
            state.noticeBarModalVisible = action.payload;
        },
        setNoticeBarVisible(state, action: { payload: boolean }) {
            state.noticeBarVisible = action.payload;
        },
        // 群设置弹框显示
        setSettingVisible(state, action: { payload: boolean }) {
            state.settingVisible = action.payload;
        },
        setSelfInfo(state, action) {
            state.selfInfo = action.payload;
        },
        setSelfMuted(state, action: PayloadAction<number>) {
            state.selfInfo = {
                ...state.selfInfo,
                muteExpiredTime: action.payload, // 禁言时间戳
            };
        },
        setHistoryVisible(state, action) {
            state.historyVisible = action.payload;
        },
        setAdminList(state, action) {
            state.adminList = action.payload;
        },
        setChatMessage(state, action) {
            // 不要去除首尾空格，textArea会丢失换行！
            state.chatMessage = action.payload;
        },
        openFace(state) {
            state.showFace = true;
        },
        closeFace(state) {
            state.showFace = false;
        },
        setShowFace(state, action) {
            state.showFace = action.payload;
        },
        deleteMessageModalShow: (state) => {
            state.deleteMessageModalVisible = true;
        },
        deleteMessageModalHidden: (state) => {
            state.deleteMessageModalVisible = false;
        },
        setConversationDetail(state, action) {
            state.conversationDetail = action.payload;
        },
        showVideoData(state, action) {
            state.videoData = {
                visible: true,
                ...action.payload
            };
        },
        hiddenVideo(state) {
            state.videoData = {
                ...state.videoData,
                visible: false
            };
        },
        setRechargeVisible(state, action) {
            state.rechargeVisible = action.payload;
        },
        setRechargeBalance(state, action) {
            state.rechargeBalanceList = action.payload;
        },
        setRechargeChannel(state, action) {
            state.rechargeChannel = action.payload;
        },
        setRechargeValue(state, action) {
            state.rechargeValue = action.payload;
        },
        resetChat: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchHistoryList.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchHistoryList.fulfilled, (state, { payload }) => {
            const { messageList } = payload;
            state.loading = false;
            state.list.push(...messageList);
        });
    }
});
export const {
    addNewMessage,
    setMessageList,
    addHistoryMessage,
    updateMessage,
    updateLocalMessage,
    updateMessageRead,
    updateGroupInfo,
    setGroupGameList,
    updateSingleInfo,
    setSelectState,
    setCurrentVoiceId,
    setShowVoice,
    setSettingVisible,
    resetChat,
    addSelectList,
    setQuoteMessage,
    deleteMessageFormStore,
    setRuleModalVisible,
    setNoticeBarModalVisible,
    setNoticeBarVisible,
    setHistoryVisible,
    setGroupOwnerList,
    setSelfInfo,
    setSelfMuted,
    setAdminList,
    openFace,
    closeFace,
    setShowFace,
    setChatMessage,
    deleteMessageModalShow,
    deleteMessageModalHidden,
    setConversationDetail,
    setRechargeVisible,
    setRechargeValue,
    setRechargeBalance,
    setRechargeChannel,
    showVideoData,
    hiddenVideo,
    updateMessageReceipt
} = chatSlice.actions;

export default chatSlice.reducer;
