import { Light, Dark } from "@/components/themeSvg";
import { initTheme } from "@/theme";
import React from "react";
import { useAppSelector } from "@/store";

function ThemeBtn() {
    const { name } = useAppSelector(state => state.common.theme);
    return <div className="handler-icon"
                onClick={ () => initTheme(name === "dark" ? "light" : "dark") }>
        {
            name === "dark" ? <Light/> : <Dark/>
        }
    </div>;
}

export default React.memo(ThemeBtn);
