import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";
import { ThemeVariable } from "@/theme";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
    theme?: ThemeVariable;
}

function ThemeIcon(props: IconProps) {
    const { theme, ...attrs } = props;
    const [color] = useThemeColor(theme);

    return <span role="img" { ...attrs }>
<svg viewBox="0 0 25 25" fill="none" color={ color }>
    <path d="M5.74377 5.64697L6.70214 6.60534" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
          strokeLinejoin="round"></path>
    <path d="M3.09717 12.0366H4.45249" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path d="M5.74377 18.4261L6.70214 17.4678" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path d="M18.5213 18.4261L17.563 17.4678" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path d="M21.1679 12.0366H19.8126" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path d="M18.5213 5.64697L17.563 6.60534" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path d="M12.1328 3.00098V4.3563" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path
        d="M12.1324 17.1488C14.9557 17.1488 17.2444 14.8601 17.2444 12.0368C17.2444 9.21354 14.9557 6.9248 12.1324 6.9248C9.30912 6.9248 7.02039 9.21354 7.02039 12.0368C7.02039 14.8601 9.30912 17.1488 12.1324 17.1488Z"
        stroke="currentColor" strokeWidth="2" strokeLinejoin="round"/>
    <path d="M12.1328 21.0721V19.7168" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
          strokeLinejoin="round"/>
</svg>

    </span>;
}

export default memo(ThemeIcon);
