import cls from "@/pages/Chat/feature/Modal/PredictionModal/style.module.scss";
import { Button, Input } from "antd-mobile";
import classNames from "classnames";
import React, { memo } from "react";
import { Add, Sub } from "@/components/themeSvg";

type BetItemProps = {
    value: number;
    label: string;
    setValue: (val: number) => void;
    selectList: { label: string; value: number }[];
}

function BetItem(props: BetItemProps) {
    const { value, setValue, label, selectList } = props;
    const handlerValue = (val: string) => {
        setValue(Number(val));
    };

    return <div className={ cls.bet_item }>
        <div className={ cls.bet_item_num }>
            <div>{ label }:</div>
            <div className={ cls.bet_item_num_input }>
                <Button className={ cls.tab } onClick={ () => setValue(value - 1) }>
                    <Sub className={ cls.icon }/>
                </Button>
                <Input className={ classNames(cls.tab, cls.tabValue) } type="number" value={ value + "" }
                       onChange={ handlerValue }/>
                <Button className={ cls.tab } onClick={ () => setValue(value + 1) }>
                    <Add className={ cls.icon }/>
                </Button>
            </div>
        </div>
        <div className={ cls.bet_item_value }>
            {
                selectList.map(item => {
                    return <Button
                        onClick={ () => setValue(item.value) }
                        className={ classNames(cls.bet_item_value_select, value === item.value && cls.bet_item_value_select_checked) }
                        key={ item.value }>{ item.label }</Button>;
                })
            }
        </div>
    </div>;

}

export default memo(BetItem);
