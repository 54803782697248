import store from "@/store";
import type { ThemeVariable } from "@/theme";

function useThemeColor(type: ThemeVariable = "--whats-color-t2") {
    const { name } = store.getState().common.theme;
    switch (name) {
        case "dark":
            // const darkColor = value[type];
            return [`var(${type})`, name];
        case "light":
        default:
            // const lightColor = value[type];
            return [`var(${type})`, name];
    }
}

export default useThemeColor;
