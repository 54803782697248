import store2 from "store2";
import { uid } from "uid";
import {
    MessageType,
    ZIMMessage,
    ZIMTextMessage,
    MessageReceiptStatus,
    createConversation,
    ConversationType,
    ZIMConversation, MessageStatus
} from "@chat/zim-sdk";

const defaultText = "Dear, Welcome to Chat.";
const STORE = "IM_PERMANENT_MESSAGE";
let userList: userItem[] = [];
type StoreData = {
    deviceCode: string;
    userID: string;
    createTime: number;
    users: {
        [key: string]: {
            conversation: ZIMConversation,
            message: ZIMMessage[]
        }
    };
}
type userItem = {
    imUserId: string;
    userName: string;
    userAvatar: string
}

// 通过常驻客服创建存储库
function getPermanentStore(users?: userItem[]): StoreData | null {
    const store: StoreData = store2.get(STORE);
    if (store) {
        // 返回, 但是要处理一下，查看常驻用户是否新增，删除，修改
        if (users) {
            const localUserMap = store.users;
            const localUsers = Object.keys(localUserMap);
            // 判断是否需要增加， 查询旧的数据，如果user不存在，那么增加
            localUsers.forEach(localUser => {
                // 新数据里是否存在
                const user = users.find(x => x.imUserId === localUser);
                // 新旧都存在，那么就要做更新
                if (user) {
                    // 更新
                    store.users[user.imUserId].conversation.conversationName = user.userName;
                    store.users[user.imUserId].conversation.conversationAlias = user.userAvatar;
                } else {
                    // 不存在，那么删除旧数据
                    delete store.users[localUser];
                }
            });
            // 是否增加。
            // 新数据如果不存在就数据，那么增加
            users.forEach(user => {
                const has = localUsers.find(localUser => localUser === user.imUserId);
                if (!has) {
                    store.users[user.imUserId] = createOnePermanent(user);
                }
            });
            store2.set(STORE, store);
            return store;
        }
        return store;
    }
    return null;
}

function createOnePermanent(user: userItem) {
    return {
        conversation: createPermanentConversation(user) as any,
        message: [createMessage(user.imUserId)]
    };
}

function getSameStore(deviceCode: string, userID: string) {
    const localStore: StoreData = store2.get(STORE);
    if (!localStore) return false;
    console.log("getSameStore", localStore);
    console.log(localStore.deviceCode === deviceCode && localStore.userID === userID, "getSameStore");
    return (localStore.deviceCode === deviceCode && localStore.userID === userID) ? localStore : false;
}

function setUserList(users: userItem[]) {
    userList = users;

}

function createPermanentStore(users: userItem[], userID: string, deviceCode: string) {
    setUserList(users);
    // const localStore: StoreData = store2.get(STORE);
    // if (localStore) {
    //     // 说明存在， 更新一下返回
    //     if (localStore.deviceCode === store2.get("deviceCode")) {
    //         return getPermanentStore(users);
    //     }
    // }
    //  创建一个新的
    // 先清除本地的，
    store2.remove(STORE);
    // 查询用户
    const store: StoreData = {
        deviceCode: deviceCode || uid(),
        userID,
        createTime: Date.now(),
        users: {}
    };
    console.log("create getSameStore", store);
    users.forEach(item => {
        store.users[item.imUserId] = createOnePermanent(item);
    });
    store2.set(STORE, store);
    return store;
}

function getPermanentConversation() {
    const store = getPermanentStore();
    if (store) {
        const users = store.users;
        return Object.keys(users).map(key => {
            return users[key].conversation;
        });
    }
    return [];
}


function createPermanentConversation(props: userItem) {
    const { imUserId, userName, userAvatar } = props;
    console.log(visitorStore.getHistoryLastMsg(imUserId),"getHistory");
    return createConversation({
        type: ConversationType.Peer,
        conversationID: imUserId,
        conversationName: userName,
        conversationAvatarUrl: userAvatar,
        lastMessage: visitorStore.getHistoryLastMsg(imUserId),
        unreadMessageCount: 1, // 创建默认未读1条
    });
}

function clearPermanentStore() {
    return store2.remove(STORE);
}

function createMessage(userID: string, data?: { text?: string; timestamp?: number }) {
    const { text, timestamp } = data || {};
    return {
        message: text || defaultText,
        type: MessageType.Text,
        messageID: uid(),
        timestamp: timestamp || Date.now(),
        senderUserID: userID,
        conversationID: userID,
        sentStatus: MessageStatus.Success,
        receiptStatus: MessageReceiptStatus.Processing
    } as ZIMTextMessage;
}

function getHistoryList(userID: string) {
    const store = getPermanentStore();
    if (store) {
        return store.users[userID]?.message || [];
    }
    return [];
}

function setHistoryList(userID: string, messageList: ZIMMessage[]) {
    const store = getPermanentStore();
    if (store) {
        let user = store.users[userID];
        if (user) {
            user.message = messageList;
            store2.set(STORE, store);
            return true;
        }
    }
    return false;
}

function updateConversation(userID: string, conversation: ZIMConversation) {
    const store = getPermanentStore();
    if (store) {
        let user = store.users[userID];
        if (user) {
            user.conversation = conversation;
            store2.set(STORE, store);
            return true;
        }
    }
    return false;
}

function getHistoryLastMsg(userID: string) {
    const list = getHistoryList(userID);
    if (list.length) {
        return list[list.length - 1];
    }
    // 不存在造一个假的
    const message = createMessage(userID);
    // 放置在历史消息中
    setHistoryList(userID, [message]);
    return message;
}

function entryConversation(userID: string) {
    // 将所有消息变为已读
    const list = getHistoryList(userID);
    const newList = list.map(item => {
        let receiptStatus = item.receiptStatus;
        // 不是自己的全已读
        if (store2.get("userID") !== item.senderUserID) {
            receiptStatus = MessageReceiptStatus.Done;
        }
        return {
            ...item,
            receiptStatus
        };
    });
    const conversation = getPermanentConversation().find(item => item.conversationID === userID);
    if (conversation) {
        updateConversation(userID, conversation);
        setHistoryList(userID, newList);
    }
    // 最后一条消息变为已读
}

function leaveConversation(userID: string, historyList: ZIMMessage[]) {
    // 将所有消息变为已读
    // 保存当前的历史消息
    const conversation = getPermanentConversation().find(item => item.conversationID === userID);
    const newList = historyList.map(item => {
        let receiptStatus = item.receiptStatus;
        // 不是自己的全已读
        if (store2.get("userID") !== item.senderUserID) {
            receiptStatus = MessageReceiptStatus.Done;
        }
        return {
            ...item,
            receiptStatus
        };
    });
    if (conversation) {
        conversation.lastMessage = newList[newList.length - 1];
        conversation.unreadMessageCount = 0;
        updateConversation(userID, conversation);
        setHistoryList(userID, newList);
    }
}

// 获取当前是否是常驻客服
function isPermanentUser(userID: string) {
    return userList.some(item => item.imUserId === userID);
}

export const visitorStore = {
    getPermanentStore,
    createPermanentStore,
    clearPermanentStore,
    setUserList,
    createMessage,
    createPermanentConversation,
    getPermanentConversation,
    getHistoryList,
    setHistoryList,
    entryConversation,
    leaveConversation,
    getHistoryLastMsg,
    isPermanentUser,
    getSameStore
};
