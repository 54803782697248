import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getVisibleUnReadCount, isVisitorUser, sendTabBarVisible, sendUnreadChange, visitorStore } from "@/utils";
import {
    getZimConversationList,
    MessageReceiptStatus,
    MessageType,
    MessageTypeValue,
    NotificationStatus,
    ZIMConversation,
    ZIMGroup,
    ConversationType
} from "@chat/zim-sdk";
import { getRoomList } from "@/apis/room";
import store2 from "store2";

export type CustomConversationItem = {
    isPassword: boolean;
    isNotInGroup: boolean;
    groupType?: 1 | 2 | 3;
} & ZIMConversation;

const initialState = {
    list: [] as CustomConversationItem[],
    loading: false,
    setting: false,
    selectList: [] as CustomConversationItem[],
    totalUnread: 0,
    goChatFirst: false,
    oneChat: {
        conversationID: "",
        groupID: ""
    },
    password: {
        groupID: "",
        visible: false,
        error: false,
        value: "",
        focused: false
    },
    joinGroupList: [] as ZIMGroup[] // 用户已经加入的群
};

// 排序
const getSortList = (list: CustomConversationItem[]) => {
    const newList = [...list];

    // 频道群优先顶置
    const channelList: CustomConversationItem[] = [];
    // 客服第二顶置
    const customList: CustomConversationItem[] = [];
    // 普通用户
    const personList: CustomConversationItem[] = [];
    // 普通房
    const normalList: CustomConversationItem[] = [];
    newList.forEach(item => {
        if (item.type === ConversationType.Peer) {
            customList.push(item);
        }
        if (item.type === ConversationType.Room) {
            if (item.groupType === 3) {
                channelList.push(item);
            } else {
                normalList.push(item);
            }
        }
    });
    customList.sort((a, b) => {
        if (a.lastMessage && !b.lastMessage) {
            return -1;
        }
        if (!a.lastMessage && b.lastMessage) {
            return 1;
        }
        return (b.lastMessage?.timestamp || 0) - (a.lastMessage?.timestamp || 0);
    });
    return [
        ...channelList,
        ...customList,
        ...normalList,
        ...personList
    ];
};
// 异步action。 获取历史会话列表
export const fetchConversationList = createAsyncThunk(
    "home/conversationList",
    async (_, { getState }) => {
        // 1.获取单人的会话列表（zim）
        // 2.获取房间列表（后台）
        // 3.客服列表（后台）
        const [res, roomRes] = await Promise.all([
            // 提取im的列表
            getZimConversationList(),
            // 获取房间列表
            getRoomList(store2.get("userID"))
        ]);
        const roomList = roomRes.data.map(item => {
            const { lastMsg = {} } = item;
            let mediaMessage: Record<string, any> = {};
            if ([MessageType.Video, MessageType.Audio].includes(+lastMsg.msg_type as MessageTypeValue)) {
                // media_duration
                mediaMessage = JSON.parse(lastMsg.msg_body);
                lastMsg.msg_body = null;
            }
            const unreadMsgCount = Math.max(item.unreadMsgCount || 0, 0);
            return {
                ...item,
                isPassword: item.groupType === 2,
                conversationAlias: "",
                conversationAvatarUrl: item.groupLogo,
                conversationID: item.groupId,
                conversationName: item.groupName,
                type: ConversationType.Room,
                notificationStatus: NotificationStatus.Notify,
                isNotInGroup: !item.fillPass,
                orderKey: 1,
                unreadMessageCount: Math.max(item.unreadMsgCount || 0, 0),
                lastMessage: lastMsg.conv_id ? {
                    conversationID: lastMsg.conv_id,
                    messageID: lastMsg.msg_id,
                    message: lastMsg.msg_body,
                    subType: +lastMsg.sub_msg_type,
                    type: +lastMsg.msg_type || MessageType.Unknown,
                    timestamp: +lastMsg.timestamp * 1000 || 0,
                    receiptStatus: unreadMsgCount ? MessageReceiptStatus.Processing : MessageReceiptStatus.Done,
                    senderUserID: lastMsg.from_user_id || "",
                    audioDuration: +mediaMessage.media_duration || 0,
                    videoDuration: +mediaMessage.media_duration || 0,
                } : null
            };
        });
        let imList = [...res.conversationList, ...roomList] as CustomConversationItem[];
        // 如果是游客，那么去除密码房和单聊，单聊走本地的
        if (isVisitorUser()) {
            imList = imList.filter(item => {
                if (item.type === ConversationType.Peer) {
                    return false;
                }
                return !item.isPassword;
            });
        }
        // 添加常驻客服
        if (!isVisitorUser()) {
            const list = visitorStore.getPermanentConversation();
            list.forEach(item => {
                // 排除自己是常驻客服情况
                if (imList.find(imConv => imConv.conversationID === item.conversationID || imConv.conversationID === store2.get("userID"))) {
                } else {
                    imList.push(item as any);
                }
            });
        }
        return {
            imList: getSortList(imList)
        };
    }
);
let isFirst = true;
// 首页会话相关
export const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        setTotalUnread(state, action) {
            state.totalUnread = action.payload;
        },
        // 更新单条会话数据
        updateConversationList(state, action) {
            const currentIndex = state.list.findIndex(item => item.conversationID === action.payload.conversationID);
            const newList = [...state.list];
            if (~currentIndex) {
                newList[currentIndex] = {
                    ...state.list[currentIndex],
                    ...action.payload
                };
                // 排序
                state.list = getSortList(newList);
            }

        },
        // 更新会话的顺序
        // 1.更新数据是置顶的，直接排序到最顶
        // 2.更新的数据不是置顶，放在所有置顶最后
        // 3.如果没有置顶，直接放在最顶
        // 新增一个会话记录
        addConversationList(state, action) {
            let data = action.payload;
            const list = Array.isArray(data) ? data : [data];
            state.list = getSortList([...state.list, ...list]);
        },
        addSelectItem(state, action) {
            const curIndex = state.selectList.findIndex(item => item.conversationID === action.payload.conversationID);
            if (~curIndex) {
                state.selectList.splice(curIndex, 1);
                if (state.selectList.length === 0) {
                    state.setting = false;
                }
            } else {
                state.selectList.push(action.payload);
            }
        },
        showSetting(state) {
            state.setting = true;
        },
        resetSetting(state) {
            state.setting = false;
            state.selectList = [];
        },
        showPassword(state, action) {
            state.password.groupID = action.payload.groupID;
            state.password.visible = true;
            state.password.focused = true;
            // 显示弹窗，关闭app tabbar
            sendTabBarVisible(false);
        },
        setPasswordError(state, action) {
            state.password.error = action.payload;
        },
        setPasswordValue(state, action) {
            state.password.value = action.payload;
        },
        setPasswordFocused(state, action) {
            state.password.focused = action.payload;
        },
        hidePassword(state) {
            state.password = initialState.password;
            sendTabBarVisible(true);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchConversationList.pending, state => {
            if (isFirst) {
                state.loading = true;
                isFirst = false;
            }
        });
        builder.addCase(fetchConversationList.fulfilled, (state, action) => {
            const { imList } = action.payload;
            let list = [...imList];
            // 更新数据
            state.loading = false;
            // 设置用户相关
            state.list = list;
            // // 获取可见会话的未读数量
            const totalUnread = getVisibleUnReadCount(list);
            sendUnreadChange(totalUnread);
        });
        builder.addCase(fetchConversationList.rejected, state => {
            state.loading = false;
        });
    }
});
export const {
    setTotalUnread,
    updateConversationList,
    addConversationList,
    resetSetting,
    showSetting,
    addSelectItem,
    showPassword,
    hidePassword,
    setPasswordValue,
    setPasswordFocused,
    setPasswordError,
} = homeSlice.actions;


export default homeSlice.reducer;
