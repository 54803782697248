import React from 'react';
import ReactDOM from 'react-dom/client';
import 'normalize.css/normalize.css';
import 'antd-mobile/bundle/css-vars-patch.css'; // 兼容ios9
import "@/assets/fonts/font.css";
import "@chat/component/dist/component.css"
import App from './App';
import reportWebVitals from './reportWebVitals';
import { setup as SDKSetup } from "@chat/zim-sdk";
// 初始化sdk
SDKSetup(parseInt(process.env.REACT_APP_ZIM_APP_ID!))
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <App/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
