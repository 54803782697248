import cls from "./style/followed.module.scss";
import { useAppDispatch } from "@/store";
import AvatarGroup from "@/components/AvatarGroup";
import { authLogin, toast } from "@/utils";
import React, { MouseEventHandler } from "react";
import { EventCardItem, showForecast } from "@/store/slice/prediction";

// 需要根据是否是发送者来判断 显示不同的样式
// 发送者 显示跟随的人数
// 接受者，显示 跟随按钮
type ShareFollowedProps = {
    self: boolean;
    data: EventCardItem;
    disabled?: boolean;
    num?: number
}

function Followed(props: ShareFollowedProps) {
    const { self, data, disabled, num = 0 } = props;
    const dispatch = useAppDispatch();

    const renderSelf = () => {
        return <div className={ cls.followedSelf }>
            <div className={ cls.followedLeft }>Followed user:</div>
            <AvatarGroup data={ { openTime: data.drawTime } as any }/>
        </div>;
    };
    const renderOther = () => {
        // 需要根据不同的类型 展示不同的modal
        const handlerClick: MouseEventHandler<HTMLDivElement> = (e) => {
            // 判断是否是游客，如果是的话，就进入登录
            e.stopPropagation();
            if (data.drawTime < Date.now()) {
                toast("This issue has ended", "error");
                return;
            }
            if (!authLogin()) return;
            dispatch(showForecast(data));
        };
        const text = "Followed Betting";
        return <div className={ cls.followedOther }>
            <AvatarGroup data={ { openTime: data.drawTime } as any } num={num}/>
            <div className={ cls.followedButton } style={ { background: "#3497F9"} }
                 onClick={ handlerClick }> { disabled ? "Prize drawn" : text } </div>
        </div>;
    };
    return <>
        { self ? renderSelf() : renderOther() }
    </>;
}

export default Followed;
