import cssVars from "css-vars-ponyfill";
import { lightTheme, darkTheme } from "./variable";
import store from "@/store";
import { setThemeValue } from "@/store/slice/common";
import store2 from "store2";

export const CHAT_THEME = "chat-theme";
type ThemeType = "dark" | "light" | "auto"

export function getTheme(themeType: ThemeType) {
    switch (themeType) {
        case "light":
            return {
                name: themeType,
                value: lightTheme
            };
        case "dark":
            return {
                name: themeType,
                value: darkTheme
            };
        case "auto":
        default:
            return {
                name: "auto",
                value: lightTheme
            };
    }
}

export type ThemeValue = ReturnType<typeof getTheme>
export type ThemeVariable = keyof ThemeValue["value"]

export function initTheme$(themeType: ThemeType) {
    const theme = getTheme(themeType);
    document.documentElement.setAttribute("data-prefers-color-scheme", theme.name);
    cssVars({
        watch: true,
        variables: theme.value,
        onlyLegacy: true
    });
    return theme;
}

export function initTheme(themeType?: ThemeType) {
    // 初始化本地主题。首先判断是否传递themeType 如果没有，走本地主题
    let localTheme: ThemeType;
    if (!themeType) {
        // 判断本地的主题
        const storeTheme = store2.get(CHAT_THEME);
        if (!storeTheme) {
            const isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
            localTheme = isDark ? "dark" : "light";
        } else {
            localTheme = storeTheme as ThemeType;
        }
    } else {
        localTheme = themeType;
    }
    store2.set(CHAT_THEME, localTheme);
    const theme = initTheme$(localTheme);
    store.dispatch(setThemeValue(theme));
}
