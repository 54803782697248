import FloatModal from "@/components/FloatModal";
import { useAppDispatch, useAppSelector } from "@/store";
import { closeForecast } from "@/store/slice/prediction";
import React, { useEffect, useMemo, useState } from "react";
import { formatPrice, isNumber } from "@chat/shared";
import cls from "./style.module.scss";
import { Checkbox, Image, Space } from "antd-mobile";
import { Reflex, DiceType, renderForecastResults, getForecastBet } from "@chat/component";
import CheckedBox from "@/components/CheckBox";
import BetItem from "@/pages/Chat/feature/Modal/PredictionModal/BetItem";
import { isVisitorUser, sendForecast, toast } from "@/utils";
import store2 from "store2";
import { setLoginModalVisible } from "@/store/slice/common";
import dayjs from "dayjs";

export const SUF_NUM = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
];


// 根据不同的类型，展示可下注的内容
function BetModal() {
    const dispatch = useAppDispatch();
    const { forecastVisible, forecastData } = useAppSelector(state => state.prediction);
    const [price, setPrice] = useState(10);
    const [betsNum, setBetsNum] = useState(1);
    const [selectValue, setSelectValue] = useState<string[]>([]);
    const [bets, setBets] = useState({
        type: "",
        results: [] as string[]
    });
    const totalPrice = useMemo(() => {
        return price * betsNum;
    }, [price, betsNum]);
    const renderBets = () => {
        switch (bets.type) {
            case "dice":
                return bets.results.map(item => {
                    return <CheckedBox value={ item } key={ item }>
                        <Reflex.Dice type={ DiceType.SUM } value={ item }/>
                    </CheckedBox>;
                });
            case "color":
                return bets.results.map(item => {
                    return <CheckedBox value={ item } key={ item }>
                        <Reflex.Color type={ item }/>
                    </CheckedBox>;
                });
            case "3Digit":
                return bets.results.map(item => {
                    return <CheckedBox value={ item } key={ item }>
                        <Reflex.Digit type={ item }/>
                    </CheckedBox>;
                });
            default:
                return null;
        }
    };
    const generateColorBet = () => {
        const list = selectValue.map(item => {
            const selectTypeSet = [];
            const selectText = `sum_${ SUF_NUM[item as any] }`;
            selectTypeSet.push(selectText.toLowerCase());
            return {

                selectValue: item,
                selectType: isNumber(item) ? 1 : 2,
                issueNo: forecastData.issueNo,
                amount: totalPrice,
                num: 1
            };
        });
        return {
            gameType: "color",
            issueNo: forecastData.issueNo,
            createOrders: list
        };
    };
    const generateDiceBet = () => {
        const list = selectValue.map(item => {
            const selectTypeSet = [];
            let selectText = "";
            if (isNumber(item)) {
                selectText = `sum_${ SUF_NUM[item as any] }`;
            } else {
                selectText = `sum_${ item }`;
            }
            selectTypeSet.push(selectText.toLowerCase());
            return {
                issueNo: forecastData.issueNo,
                baseAmount: totalPrice,
                totalAmount: totalPrice,
                selectTypeSet,
            };
        });
        return {
            gameType: "dice",
            issueNo: forecastData.issueNo,
            createOrders: list
        };
    };
    const generateBet = (type: "dice" | "3digit" | "color") => {
        switch (type) {
            case "dice":
                return generateDiceBet();
            case "color":
                return generateColorBet();
            case "3digit":
                return {
                    gameType: type,
                    issueNo: forecastData.issueNo,
                    createOrders: []
                };
        }
    };
    const handleBetting = () => {
        if (selectValue.length === 0) return;
        const betResult = generateBet(forecastData.gameType);
        // 判断是否是登陆状态，如果是登陆状态，发送请求，否则跳转到登陆页面
        if (isVisitorUser()) {
            dispatch(setLoginModalVisible(true))
            return;
        }
        sendForecast({
            userID: store2.get("userID"),
            ...betResult
        })
    };

    const handlerRender = () => {
        return <div className="followedModal-handler">
            <div className="total">
                <div className="total-price">₹ { formatPrice(totalPrice * selectValue.length) }</div>
                <div
                    className="total-number"> { betsNum } Numbers
                </div>
            </div>
            <div className="followed-button" style={ {
                opacity: selectValue.length === 0 ? 0.6 : 1,
                pointerEvents: selectValue.length === 0 ? "none" : "auto"
            } }
                 onClick={ handleBetting }>Followed Betting
            </div>
        </div>;
    };

    useEffect(() => {
        if (forecastVisible) {
            const bets = getForecastBet(forecastData);
            setBets(bets);
            setSelectValue(bets.results);
        }
    }, [forecastData, forecastVisible]);
    return <FloatModal visible={ forecastVisible } onClose={ () => {
        dispatch(closeForecast());
    } } handler={ handlerRender() }>
        <div className={ cls.container }>
            <div className={ cls.detail }>
                <Image src={ forecastData.gameIcon } className={ cls.pic }/>
                <div className={ cls.detail__info }>
                    <div className={ cls.name }>{ forecastData.gameName }</div>
                    <div className={ cls.id }>{ forecastData.issueNo }</div>
                </div>
                <div className={ cls.detail__time }>
                    <div className={ cls.drawText }>Draw time</div>
                    <div className={ cls.drawTime }>{
                        dayjs(forecastData.drawTime).format("DD-MM-YY hh:mm:ss")
                    }</div>
                </div>
            </div>
            <div className={ cls.line }></div>
            <div className={ cls.forecast }>
                <div className={ cls.result }>
                    <div className={ cls.result_label }>forecast results:</div>
                    <div className={ cls.result_value }>
                        {
                            renderForecastResults({
                                result: forecastData.forecastResults,
                                gameType: forecastData.gameType
                            })
                        }
                    </div>
                </div>
                <Checkbox.Group value={ selectValue }
                                onChange={ val => {
                                    setSelectValue(val as string[]);
                                } }>
                    <Space block className={ cls.select_space }
                           justify={ bets.results.length === 3 ? "between" : "stretch" }
                           style={ { "--gap": bets.results.length < 3 ? 33 + "%" : "8px" } }>
                        {
                            renderBets()
                        }
                    </Space>
                </Checkbox.Group>
            </div>
        </div>
        <div className={ cls.bet }>
            <BetItem label="Bet Amount"
                     setValue={ (val: number) => {
                         if (val < 10) {
                             toast("Minimum Amount ₹10!", "error");
                             return;
                         }
                         setPrice(val);
                     } }
                     selectList={ [
                         {
                             label: "₹10",
                             value: 10
                         },
                         {
                             label: "₹100",
                             value: 100
                         },
                         {
                             label: "₹500",
                             value: 500
                         },
                         {
                             label: "₹1000",
                             value: 1000
                         }
                     ] }
                     value={ price }
            />
            <div className={ cls.line }></div>
            <BetItem label="Bet Count"
                     setValue={ (val: number) => {
                         if (val < 1) {
                             toast("Minimum Count 1!", "error");
                             return;
                         }
                         setBetsNum(val);
                     } }
                     selectList={ [
                         {
                             label: "1bet",
                             value: 1
                         },
                         {
                             label: "3bets",
                             value: 3
                         },
                         {
                             label: "10bets",
                             value: 10
                         },
                         {
                             label: "25bets",
                             value: 25
                         }
                     ] }
                     value={ betsNum }
            />
        </div>
    </FloatModal>;
}

export default BetModal;
