export const lightTheme = {
    "--whats-white": "#fff",
    // 模块大标题，重点文字部分
    "--whats-color-t1": "#0B141B",
    "--whats-color-t2": "#17181C",
    "--whats-color-t3": "#566369",
    "--whats-color-t4": "#8696A5",
    // 主题色
    "--whats-base-color": "#1DAB61",
    "--whats-bg": "#FFF",

    // 卡片
    "--whats-card": "#FFF",
    // 间隔线
    "--whats-line": "#F4F4F4",
    "--whats-card-line": "#F4F4F4",
    // 标题栏
    "--whats-title": "#FFF",
    // 聊天气泡
    "--whats-main-bubble": "#D8FDD2",
    "--whats-sub-bubble": "#FFF",

    // 点击效果
    "--whats-active": "#D8FDD2",
    // 首页底部选择框
    "--home-selectBar-bg": "#F6F5F3",
    // 聊天信息的选择样式
    "--whats-message-select-bg": "rgba(216,253,210,0.4)",

    // 按钮icon
    "--whats-button-icon": "#FFF",
    // 顶部logo
    "--whats-chat-logo": "#1DAB61",
    // 顶部公告的渐变
    "--whats-notice-shadow": "linear-gradient(90deg, rgba(255,255,255,0) 0%, #ffff 70%)",

    // 底部引用框背景
    "--quoto-bg": "#F5F7F6",
    // 引用框基础色
    "--quoto-base-color": "var(--whats-base-color)",
    // 引用别人的
    "--quoto-second-color": "#3497F9",
    // 信息内的引用框背景
    "--quoto-info-base-color": "rgba(0,0,0,0.05)",
    "--quoto-info-second-color": "#F5F7F6",
    // 弹框底部操作
    "--modal-handler-bg": "#FFF",
    // 群设置
    "--setting-bg": "#F9F9F9",

    "--role-tag-super-bg": "#D8FDD2",
    "--role-tag-super-text": "#195A2E",
    "--role-tag-group-bg": "#FCE1D2",
    "--role-tag-group-text": "#894B28",
    // voice的shadow
    "--voice-shadow": "linear-gradient(90deg, #FFF 40%, rgba(255, 255, 255, 0.00) 100%)",
    "--voice-wave-bar": "#ABC9C7",
    "--voice-wave-progress": "#84969A",


    // 辅助色
    "--secondary-color": "#F15802",
    "--secondary-border-color": "#F15802",
    // 聊天背景相关
    //背景色
    "--whats-chat-bg": "#F7F4EF",
    // 聊天界面相关
    // 系统消息气泡颜色
    "--system-message-bg": "#F7F7F7",
    "--system-message-shadow": "0px 0px 1px 0px rgba(0, 0, 0, 0.15)",
    // 分享卡片的背景色
    "--share-bg": "#fff",
    // 跟投弹窗变量
    "--bets-head-bg": "#E9F5EF",
    "--bets-item-bg": "#F0FDF6",
    // 底部表情框icon选中
    "--expression-active": "#F1F2F6",
    "--expression-bg": "#FFF",

    "--recharge-input-bg": "#F7F7F7",
    "--recharge-input-border": "#D8E0EA",
    "--recharge-card-bg": "linear-gradient(180deg, #F2F4F9 0%, #F3F5FB 100%)",
    "--recharge-card-border": "rgba(247, 247, 247, 0.50)",
    "--recharge-card-shadow": "0px 2px 0px 0px #C3C8DC",

    "--predict-line": "#eee",
    "--predict-bg": "#F8F8F8",
    "--predict-btn": "#EAEBF4",

    "--predict-input-bg": "#EFF1F7",
    "--predict-select-bet": "#D8FDD2",
    "--predict-select-bet-checked": "#1DAB61",
    "--predict-table-thead": "#E9ECF5",
    "--predict-table-even": "#F8F9FF",
    //-----------分割线---------

    "--select-item-color": "rgba(0, 58, 140, 0.4)",
    "--font-color-base": "#fff",
    "--font-size-small": "14px",


    "--bubble-other": "#1F2C34",
    "--bubble-mine": "#003A8C",
    // admin聊天气泡样式
    "--admin-bubble-color": "#F53F3F",
    "--admin-bubble-bg": "#343333",

    "--chat-select-bg": "#121B22",

    "--primary-text-color": "#20C164",
    "--primary-bg-color": "#0B141B",
    "--primary-border-color": "#20C164",


    "--notice-bg-color": "#003A8C",
    "--notice-icon-color": "#EC4E49",


    "--muted-tab-bg": "rgba(0, 0, 0, 0.05)",

    "--heart-bg": "rgba(255, 255, 255, 0.60)",


    // person相关
    "--person-filter-bg": "rgba(255,255,255,0.8)",
    // 未读字体颜色
    "--read-text": "#0A151B",

    // 底部输入框颜色
    "--bottom-box-bg": "#0B141B",


};

