import {
    login,
    addEvent,
    offEvent,
    logout,
    deleteAllConversation,
    deleteAllMessage,
    getZimConversationList
} from "@chat/zim-sdk";
import { useEffect, useState } from "react";
import { useAppSearchParams, useChatMode } from "@chat/shared";
import store2 from "store2";
import { CHAT_THEME, initTheme } from "@/theme";
import { getImToken } from "@/apis/im";
import { CHAT_PACKAGE_ID, toast, visitorStore } from "@/utils";
import { useAppDispatch } from "@/store";
import { setLogged, updateSelf } from "@/store/slice/user";
import { fetchIdentity, setExpiredVisible, setFull } from "@/store/slice/common";

const getVisitorState = (data: any): boolean => {
    if (data) {
        return data === "true";
    }
    return store2.get("visitor");
};
// 1.先判断是否是游客，如果是游客不走登录
// 2.不是游客，获取当前的originToken，如果存在就登录，不存在取本地的imtoken，存在就登录。不存在就报错

export function useZimInit() {
    const [errPage, setErrPage] = useState(false);
    const [mounted, setMounted] = useState(false);
  const originToken = useAppSearchParams(
    "originToken",
    store2.get("originToken")
  );
  let deviceCode = useAppSearchParams("deviceCode", store2.get("deviceCode"));
  const packageId = useAppSearchParams(
    "packageId",
    store2.get("packageId") || 5
  );
  const pushToken = useAppSearchParams(
    "pushToken",
    store2.get("pushToken") || null
  );
  let full = useAppSearchParams("full", store2.get("full") || "0"); // 1显示
  const visitorData = useAppSearchParams("isVisitor");
  const isVisitor = getVisitorState(visitorData);
  const dispatch = useAppDispatch();
    const {chatMode} = useChatMode()
  const getUserToken = async () => {
    store2.set("packageId", parseInt(packageId));
    store2.set("full", full);
    store2.set("visitor", isVisitor);
    document.documentElement.setAttribute(CHAT_PACKAGE_ID, packageId);
    store2.set("pushToken", pushToken);
    dispatch(setFull(full == 1));
    addEvent({
      getImToken: () =>
        getImToken({
          deviceCode: isVisitor ? deviceCode : null,
          fbToken: pushToken,
        }),
      afterRefreshToken(token) {
        store2.set("imToken", token);
      },
      kickedOut: () => {
        dispatch(setExpiredVisible(true));
      },
    });
    if (isVisitor) {
      // 1. 游客
      setMounted(true);
      store2.remove("originToken");
      return null;
    } else {
      // 1.不是游客，读取origintoken
      store2.set("originToken", originToken);
    }
    try {
      const res = await getImToken({
        deviceCode: isVisitor ? deviceCode : null,
        fbToken: pushToken,
      });
      if (res.code === 0) {
        const result: Record<any, any> = {
          visitor: isVisitor,
          deviceCode,
          imToken: res.data.token,
          userID: res.data.userID,
          reuse: !!res.data.reuse,
        };
        if (!isVisitor) {
          result.originToken = originToken;
        }
        return result;
      } else {
        return Promise.reject(res);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  };
  const init = async () => {
        if (chatMode === "inside") {
            // 内嵌为亮色
            store2.set(CHAT_THEME,"light" )
        }
    initTheme();
    const res = await getUserToken().catch((err) => {
      toast(err.msg || "something went wrong", "error");
      setErrPage(true);
    });

    if (res) {
      if (!res.visitor) {
        // 如果当前不是游客，清除一次缓存
        if (visitorStore.getPermanentStore()?.userID !== res.userID) {
          visitorStore.clearPermanentStore();
        }
      }
      store2.setAll(res);
      const { imToken, userID } = res;
      if (userID && imToken) {
        dispatch(fetchIdentity()).then((identRes: any) => {
          const isVisitor = res.visitor;
          // 获取客服，创建常驻本地数据
          const allIdentity = identRes.payload.filter((item: any) => {
            return ["1", "2"].includes(item.imPermanent);
          });
          let identity: any[] = [];
          const onlineIdentity = allIdentity.filter(
            (item: any) => item.imUserStatus === "1"
          );
          if (onlineIdentity.length) {
            identity = onlineIdentity;
          } else {
            identity = allIdentity;
          }
          // 随机取一个
          const randomIdentity =
            identity[Math.floor(Math.random() * identity.length)];

          const store = visitorStore.getSameStore(
            store2.get("deviceCode"),
            userID
          );
          visitorStore.setUserList(identity);
          const identityStr: string[] = identity.map(
            (tity: any) => tity.imUserId
          );
          if (store) {
            const exists = Object.keys(store.users).find((item) => {
              return identityStr.includes(item);
            });
            if (!exists) {
              visitorStore.createPermanentStore(
                [randomIdentity].filter(Boolean),
                userID,
                res.deviceCode
              );
            }
          } else {
            visitorStore.createPermanentStore(
              [randomIdentity].filter(Boolean),
              userID,
              res.deviceCode
            );
          }
          login(userID, imToken)
            .then((userInfo) => {
              // 更新当前登录用户的信息
              dispatch(updateSelf(userInfo));
              if (isVisitor && res.reuse) {
                // 先调取会话
                getZimConversationList().then((list) => {
                  const p = list.conversationList.map((conversation) =>
                    deleteAllMessage(conversation.conversationID)
                  );
                  Promise.all(p).then(() => {
                    deleteAllConversation().then(() => {
                      setMounted(true);
                    });
                  });
                });
              } else {
                setMounted(true);
              }
              // 获取公共客服列表
              if (!isVisitor) {
                // 非游客
                dispatch(setLogged(true));
              }
            })
            .catch((err) => {
              dispatch(setExpiredVisible(true));
              console.error(err);
            });
        });
      }
    }
  };
  useEffect(() => {
    init();
    return () => {
      offEvent();
      logout();
    };
  }, []);
  // 登录
  return {
    errPage,
    mounted,
  };
}
