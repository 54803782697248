
// 等待的弹幕，满足先进先出特点，采用队列模拟
export class WaitingQueue<T = any> {
    private list: T[] = [];

    // 添加一个等待对象
    enqueue(data: T) {
        this.list.push(data);
    }
    get value() {
        return this.list;
    }

    // 去除队头
    dequeue(): T | undefined {
        return this.list.shift();
    }

    front(): T | undefined {
        return this.list[0];
    }

    isEmpty() {
        return this.list.length === 0;
    }

    size() {
        return this.list.length;
    }

    clear() {
        this.list.length = 0;
    }
}
