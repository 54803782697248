import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";
import { ThemeVariable } from "@/theme";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
    theme?: ThemeVariable;

}

function ThemeIcon(props: IconProps) {
    const { theme, ...attrs } = props;
    const [color] = useThemeColor(theme || "--whats-color-t3");

    return <span role="img" { ...attrs }>
         <svg viewBox="0 0 12 13" color={ color }>
            <path d="M9 3.19141L3 9.19141" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 3.19141L9 9.19141" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </span>;
}

export default memo(ThemeIcon);
