import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from "@/store";
// import { getHistoryWin } from "@/apis/im";
import { AnimatePresence, motion } from "framer-motion";
import _ from "lodash";
import { formatPrice } from "@chat/shared";

type HistoryItem = {
    winner: string;
    price: string;
    game: string;
}
const variants = {

    enter: {
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
            stiffness: 200,
            restDelta: 20,
        }
    },
    exit: {
        y: -5,
        opacity: 0,
        transition: {
            type: "spring",
            stiffness: 400,
            damping: 40
        }
    }
};
const WinHistory = () => {
    const { noticeBarVisible, historyVisible, groupGameNoticeList } = useAppSelector(state => state.chat);
    const [list, setList] = useState<HistoryItem[]>([]);
    const timer = useRef<NodeJS.Timeout>();
    const moveToEnd = (arr: any[]) => {
        const moveFirstToEndFlow = _.flow([_.tail, (rest) => _.concat(rest, _.head(arr))]);
        return moveFirstToEndFlow(arr);
    };
    const startScroll = () => {
        stopScroll();
        timer.current = setInterval(() => {
            const newList = moveToEnd(list);
            setList(newList);
        }, 1000 * 2);
    };
    const stopScroll = () => {
        timer.current && clearInterval(timer.current);
    };

    useEffect(() => {
        setTimeout(() => {
            setList(groupGameNoticeList);
        }, 1000)
    }, [groupGameNoticeList]);
    useEffect(() => {
        if (list.length) {
            startScroll();
        }
        return () => {
            stopScroll();
        };
    }, [list]);
    if (!historyVisible || !list.length) return null;
    return (
        <div className="win-history" style={ { top: noticeBarVisible ? 40 + 'px' : 0 } }>
            <div className="win-history-list">
                <AnimatePresence mode="popLayout">
                    {
                        list.slice(0, 2).map(item => {
                            return <motion.div layout
                                               variants={ variants }
                                               initial="enter"
                                               exit="exit"
                                               className="win-history-item"
                                               key={ item.winner }
                            >
                                { item.winner } won <span className="price">₹{ formatPrice(+item.price) }</span> in
                                the { item.game } game
                            </motion.div>;
                        })
                    }
                </AnimatePresence>
            </div>
        </div>
    );
};

export default WinHistory;
