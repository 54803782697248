import cls from "@/pages/Chat/feature/Modal/PredictionModal/style.module.scss";
import { Image } from "antd-mobile";
import pic from "@/assets/game-pic/scratch off.png";
import dayjs from "dayjs";
import PredictionTable, { ColumnProps } from "@/pages/Chat/feature/Modal/PredictionModal/PredictionTable";
import { renderForecastResults, renderUserWin } from "@chat/component";
import { parseStr } from "@chat/shared";
import AvatarGroup from "@/components/AvatarGroup";
import { RightArrow } from "@/components/themeSvg";
import { ForecastDataItem } from "@/store/slice/prediction";
import { HistoryData } from "@/pages/Chat/feature/Modal/PredictionModal/HistoryModal";

type HistoryListProps = {
    forecastData: ForecastDataItem;
    historyData: HistoryData;
    onClick?: (data: any) => void;
    loading: boolean
}

export default function HistoryList(props: HistoryListProps) {
    const { forecastData, historyData, onClick, loading } = props;
    const columns: ColumnProps[] = [
        {
            title: <div className={ cls.issue }>ISSUE</div>,
            dataIndex: "issueNo",
            key: "issueNo",
            render(val) {
                return <div className={ cls.issue }>{ val }</div>;
            }
        },
        {
            title: <>
                <div>FORECAST</div>
                <div>RESULTS</div>
            </>,
            dataIndex: "forecastResults",
            key: "forecastResults",
            render(_val, record) {
                return <div style={ { display: "flex", justifyContent: "center" } }>
                    {
                        record.status === 0 ? "-" :
                            renderForecastResults({
                                result: parseStr(record.forecastResults),
                                gameType: record.gameType
                            })
                    }
                </div>;
            }
        },
        {
            title: <>
                <div>WINNING</div>
                <div>RESULTS</div>
            </>,
            key: "winningResults",
            dataIndex: "winningResults",
            render(val, record) {
                return <div style={ { display: "flex", justifyContent: "center", gap: 1 } }>
                    {
                        val ?
                            renderForecastResults({
                                result: parseStr(val),
                                gameType: record.gameType
                            }) : "-"
                    }
                </div>;
            }
        },
        {
            title: <>
                <div>WINNING</div>
                <div>PRIZES</div>
            </>,
            dataIndex: "winningResults1",
            key: "winningResults1",
            render(_val, record) {
                return <div style={ { display: "flex", justifyContent: "center", gap: 1 } }>
                    {
                        record.winningResults ?
                            renderUserWin({
                                result: parseStr(record.winningResults),
                                gameType: record.gameType
                            }) : "-"
                    }
                </div>;
            }
        },
        // {
        //     title: "STATUS",
        //     dataIndex: "status",
        //     key: "status",
        //     render(val) {
        //         const getText = () => {
        //             switch (val) {
        //                 case 1:
        //                     return <div className={ cls.hit }>Hit</div>;
        //                 case 2:
        //                     return <div className={ cls.missed }>Missed</div>;
        //                 default:
        //                     return <div className={ cls.missed }>-</div>;
        //             }
        //         };
        //         return <div className={ cls.status }>
        //             { getText() }
        //         </div>;
        //
        //     }
        // },
        {
            title: "WINNER LIST",
            dataIndex: "winnerList",
            key: "winnerList",
            render(_val, record) {
                return <div style={ { display: "flex", alignItems: "center", gap: 4 } }>
                    <AvatarGroup data={ { openTime: record.drawTime } as any }/>
                    <RightArrow className={ cls.arrow } theme="--whats-color-t3"/>
                </div>;
            }
        }
    ];
    return <>
        <div className={ cls.container }>
            <div className={ cls.detail }>
                <Image src={ pic } className={ cls.pic }/>
                <div className={ cls.detail__info }>
                    <div className={ cls.name }>{ forecastData.gameName }</div>
                    <div className={ cls.id }>{ forecastData.issueNo }</div>
                </div>
                <div className={ cls.detail__time }>
                    <div className={ cls.drawText }>Draw time</div>
                    <div className={ cls.drawTime }>{ dayjs(forecastData.drawTime).format("DD-MM-YY hh:mm:ss") }</div>
                </div>
            </div>
            <div className={ cls.line }></div>
            <div className={ cls.analyze }>
                <div className={ cls.analyze_item }>
                    <div className={ cls.analyze_item_label }>Prediction Count</div>
                    <div className={ cls.analyze_item_value }>{ historyData.predictCount }</div>
                </div>
                <div className={ cls.analyze_item }>
                    <div className={ cls.analyze_item_label }>Hit Count</div>
                    <div className={ cls.analyze_item_value }
                         style={ { textAlign: "center" } }>{ historyData.hitCount }</div>
                </div>
                <div className={ cls.analyze_item }>
                    <div className={ cls.analyze_item_label }>Accuracy Rate</div>
                    <div className={ cls.analyze_item_value }
                         style={ { textAlign: "end" } }>{ Math.floor((historyData.predictRate * 100)) }%
                    </div>
                </div>
            </div>
        </div>
        <PredictionTable rowKey="issueNo" loading={ loading } dataSource={ historyData.list } columns={ columns }
                         rowClick={ onClick }/>
    </>;
}
