import { Checkbox } from "antd-mobile";
import { getVmSize } from "@chat/shared";
import { CSSProperties, ReactNode } from "react";

type CheckBoxProps = {
    checked?: boolean;
    setChecked?: (checked: boolean) => void;
    children?: ReactNode;
    iconSize?: string;
    value?: string
}

const SvgIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                           viewBox="0 0 17 16"
                           fill="none">
    <rect x="0.5" width="16" height="16" rx="8" fill="#1DAB61"/>
    <path d="M5.29883 8.24654L7.53883 10.4004L11.6988 6.40039" stroke="#F9F9FB" strokeWidth="2"
          strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

function CheckedBox(props: CheckBoxProps) {
    const { checked, setChecked, children, value } = props;

    return <Checkbox
        checked={ checked }
        value={ value }
        onChange={ setChecked }
        icon={ checked => {
            const style: CSSProperties = {
                width: getVmSize(16),
                height: getVmSize(16),
                border: !checked ? `${ getVmSize(2) } solid #e5e5e5` : "none",
                borderRadius: "50%",
                boxSizing: "border-box",
                display: "inline-block"
            };
            return <div style={ style }>
                {
                    checked && <SvgIcon/>
                }

            </div>;
        }
        }
    >{ children }</Checkbox>;
}

export default CheckedBox;
