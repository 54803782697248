import { useAppDispatch, useAppSelector } from "@/store";
import {
    fetchConversationList,
    resetSetting
} from "@/store/slice/home";
import { toast } from "@/utils";
import {
    Back,
    // Diturbing,
    // DiturbingOff,
    // TopGroup,
    // TopGroupOff,
    Rubbish,
} from "@/components/themeSvg";
import {
    ConversationType,
    // NotificationStatus,
    // setConversationNotificationStatus,
    // updateConversationPinnedState
    deleteConversation
} from "@chat/zim-sdk";

function SelectNavbar() {
    const dispatch = useAppDispatch();
    const { setting, selectList } = useAppSelector(state => state.home);
    const {identityList} = useAppSelector(state => state.common)
    const num = selectList.length;
    // const showPinned = useMemo(() => {
    //     return !!selectList.find(item => !item.isPinned);
    // }, [selectList]);
    // const showNotRecved = useMemo(() => {
    //     return !!selectList.find(item => item.notificationStatus === NotificationStatus.Notify);
    // }, [selectList]);
    const closebar = () => {
        resetState();
    };
    // 重置当前组件状态
    const resetState = () => {
        dispatch(resetSetting());
    };
    // const personIngroup = () => {
    //     for (let i = 0; i < selectList.length; i++) {
    //         const item = selectList[i];
    //         if (item.isPassword) {
    //             return false;
    //         }
    //     }
    //     return true;
    // };
    // const beforeHandle = () => {
    //     if (!personIngroup()) {
    //         toast("This room is password protected. Please enter the password to continue.", "error");
    //         return false;
    //     }
    //     return true;
    // };
    // const handleTop = async () => {
    //     if (!beforeHandle()) return;
    //     const promises = selectList.map(item => {
    //         return updateConversationPinnedState(showPinned, item.conversationID);
    //     });
    //     await Promise.all(promises);
    //     dispatch(fetchConversationList());
    //     resetState();
    // };
    // const handleMuted = async () => {
    //     if (!beforeHandle()) return;
    //     const status = showNotRecved ? NotificationStatus.DoNotDisturb : NotificationStatus.Notify;
    //     const promises = selectList.map(item => {
    //         return setConversationNotificationStatus(status, item.conversationID);
    //     });
    //     await Promise.all(promises);
    //     dispatch(fetchConversationList());
    //     resetState();
    // };
    const handleRemove = () => {
        let canDelete = true;
        let error = "";
        for (let i = 0; i < selectList.length; i++) {
            const item = selectList[i];
            if (item.type !== ConversationType.Peer) {
                canDelete = false;
                error = "Group"
                break;
            }
            const identity = identityList.filter((identity: any) => {
                return ["1", "2"].includes(identity.imPermanent)
            }).map(item => item.imUserId);
            if (identity.includes(item.conversationID)) {
                canDelete = false;
                error = "Customer Service"
                break;
            }

        }
        if (!canDelete) {
            toast(error + " cannot be deleted", "error");
            return;
        }
        Promise.all(selectList.map((item) => deleteConversation(item.conversationID))).then(() => {
            dispatch(fetchConversationList());
            toast("Session deleted successfully");
            resetState();
        })
    };
    if (!setting) return null;

    return <>
        <div className="selectBar">
            <div className="selectBar-content">
                <div className="selectBar-left">
                    <Back className="navbar-backIcon" onClick={ closebar }></Back>
                    <div>{ num }</div>
                </div>
                <div className="selectBar-right">
                    <div onClick={ handleRemove } className="icon">
                        <Rubbish></Rubbish>
                    </div>
                    {/*<div onClick={ handleTop } className="icon">*/ }
                    {/*    {*/ }
                    {/*        showPinned ? <TopGroup></TopGroup> : <TopGroupOff></TopGroupOff>*/ }
                    {/*    }*/ }
                    {/*</div>*/ }
                    {/*<div onClick={ handleMuted } className="icon">*/ }
                    {/*    {*/ }
                    {/*        showNotRecved ? <DiturbingOff></DiturbingOff> : <Diturbing></Diturbing>*/ }
                    {/*    }*/ }
                    {/*</div>*/ }
                </div>
            </div>
        </div>
    </>;
}


export default SelectNavbar;
