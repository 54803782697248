import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@/store";
import { emitter, isDefaultUser, toast } from "@/utils";
import {
    addNewMessage,
    closeFace,
    setChatMessage,
    setConversationDetail,
    setQuoteMessage,
    updateLocalMessage,
} from "@/store/slice/chat";
import {
    getConversationType, MessageReceiptStatus,
    MessageType,
    queryConversation,
    SendMessageProps,
    ZIMImageMessage,
    ZIMMessage
} from "@chat/zim-sdk";
import {
    ConversationType,
    sendMessage as sendSDKMessage,
    MessageStatus,
    getPicInfo
} from "@chat/zim-sdk";
import _ from "lodash";
import { sendFbMessage } from "@/apis/im";
import { useChatMode } from "@chat/shared";

let prev = Date.now();
export default function useSendMessage() {
    // 信息上传进度
    const onMediaUploadingProgress = (message: ZIMMessage, currentFileSize: number, totalFileSize: number) => {
        const { localMessageID } = message;
        // 更新进度
        dispatch(updateLocalMessage({
            localMessageID,
            progress: currentFileSize / totalFileSize * 100,
        }));
    };
    const {chatMode} = useChatMode()
    // 客户端生成消息，服务端生成消息之前
    const addLocalMessage = (message: any) => {
        dispatch(addNewMessage(message));
        setTimeout(() => {
            emitter.emit("CHAT_LIST_SCROLL_TO_BOTTOM", { behavior: "smooth" });
        }, 200)
    }
    const onMessageAttached = (message: ZIMMessage) => {
        const localMessage: any = _.cloneDeep(message);
        // 判断是否是图片
        if (message.type === MessageType.Image && localMessage.fileLocalPath) {
            getPicInfo((localMessage as any).fileLocalPath).then(res => {
                (localMessage as any).fileLocalPath = "";
                (localMessage as any).thumbnailWidth = res.width;
                (localMessage as any).thumbnailHeight = res.height;
                (localMessage as ZIMImageMessage).thumbnailDownloadUrl = res.src;
                addLocalMessage(localMessage);
            });
        } else if ([MessageType.Video, MessageType.Audio].includes(message.type as any) && localMessage.fileLocalPath) {
            (localMessage as any).fileLocalPath = "";
            addLocalMessage(localMessage);
        } else {
            addLocalMessage(localMessage);
        }
    };
    const { conversationDetail } = useAppSelector(state => state.chat);
    const dispatch = useAppDispatch();
    const sendMessage = useCallback(
        async ({ conversationID, message, originalMessage }: SendMessageProps) => {
            // 判断当前用户是否存在权限发送信息
            // 内嵌的不对名字做处理
            if (conversationDetail.type !== ConversationType.Peer && chatMode === "normal" && isDefaultUser()) {
                return;
            }
            // 判断是否是空
            if (message.type === MessageType.Text && !(message.message as string).trim()) {
                toast("The content cannot be empty!", "error");
                return;
            }
            if (Date.now() - prev < 1000) {
                toast("Please don't send it so fast.", "error");
                return;
            } else {
                prev = Date.now();
            }
            try {
                // 清空数据
                dispatch(setChatMessage(''));
                // 清空引用
                dispatch(setQuoteMessage(null));
                dispatch(closeFace());
                const res = await sendSDKMessage({
                    message,
                    originalMessage,
                    conversationID,
                    notification: {
                        onMediaUploadingProgress,
                        onMessageAttached
                    },
                    fbFetch: sendFbMessage
                });
                // 需要做一个处理，媒体文件，不需要更新地址，防止闪烁
                dispatch(updateLocalMessage(res?.message));
                if (getConversationType(conversationID) === ConversationType.Room) {
                    setTimeout(() => {
                        const newMessage = {
                            ...res.message,
                            receiptStatus: MessageReceiptStatus.Done
                        }
                        dispatch(updateLocalMessage(newMessage));
                    }, Math.random() * 1000 * 5);
                }

                if (!conversationDetail.conversationID && getConversationType(conversationID) === ConversationType.Peer) {
                    // 更新会话信息
                    queryConversation(conversationID).then(res => {
                        dispatch(setConversationDetail(res.conversation));
                    });
                }
                return res?.message;
            } catch (error: any) {
                console.error(error, "error");
                if (error.code === 6000230) {
                    toast("Contains sensitive words", "error");
                } else {
                    toast(error.message, "error");
                }
                dispatch(updateLocalMessage({ ...message, sentStatus: MessageStatus.Failed }));
            }
        },
        [conversationDetail, chatMode],
    );

    return {
        sendMessage,
    };
}
