// 中转，根据不同的type展示不同的卡片
import { Image } from "antd-mobile";
import ShareFollowed from "./shareFollowed";
import React, { memo, useEffect, useState } from "react";
import ColorGame from "./Color";
import Dice from "@/pages/Chat/feature/ShareCards/Dice";
import Digit from "@/pages/Chat/feature/ShareCards/Digit";
import Satta from "@/pages/Chat/feature/ShareCards/Satta";
import CountTime from "@/components/CountTime";
import LuckMessage from "@/pages/Chat/feature/MessageItem/LuckMessage";
import type { CardItemItem } from "@/utils";
import classNames from "classnames";
import { TGameCode } from "@/store/slice/followed";
import QuickRace from "@/pages/Chat/feature/ShareCards/QuickRace";
import QuickComprehensive from "@/pages/Chat/feature/ShareCards/QuickComprehensive";
import { replaceOssImage, parseStr } from "@chat/shared";
import MessageState from "@/pages/Chat/feature/MessageItem/MessageState";
import { ZIMCustomMessage, useUserInfo } from "@chat/zim-sdk";

export type CardTypeData = {
    shareId: string;
    shareName: string;
    rawData: any;
    gameIconUrl: string;
    gameName: string;
    issNo: string;
    gameType: TGameCode;
    openTime: number;
    result: string;
    totalAmount: number;
    totalAwardAmount: number;
    from?: "order";
    count?: number;
    list: CardItemItem[];
}
type ShareCardsProps = {
    self: boolean;
    data: ZIMCustomMessage;
    quote?: boolean // 是否是引用信息
}
const renderCardContent = (type: TGameCode, data: CardItemItem[]) => {
    switch (type) {
        case "color":
            return <ColorGame data={ data }/>;
        case "dice":
            return <Dice data={ data }/>;
        case "3Digit":
        case "quick3d":
            return <Digit data={ data }/>;
        case "satta":
        case "matka":
            return <Satta data={ data }/>;
        case "quickRace":
            return <QuickRace data={ data }/>;
        case "quickStateLottery":
        case "stateLottery":
            return <QuickComprehensive data={ data }/>;
        default:
            return <div></div>;
    }
};

function ShareCards(props: ShareCardsProps) {
    const { self, data: messageData, quote } = props;
    const [cardData, setCardData] = useState({} as CardTypeData);
    const drawTime = cardData.openTime;
    const [resetEnd, setResetEnd] = useState(false);
    const { userInfo } = useUserInfo(messageData.senderUserID);
    const [isEnd, setIdEnd] = useState(true);
    useEffect(() => {
        try {
            let customData = parseStr(messageData.message);
            if (customData.userId) {
                const needType = [
                    "matka",
                    "satta",
                    "scratch",
                    "statelottery",
                    "3digit",
                    "quick3d"
                ];
                let gameName = customData.data.gameName;
                if (needType.includes(customData.data.gameType.toLowerCase())) {
                    gameName = [customData.data.gameType, customData.data.gameName].join("-");
                }
                setCardData({
                    ...customData.data,
                    shareId: customData.userId,
                    shareName: userInfo?.userName,
                    gameName
                });
                // 判断当前是否已经结束
                if (customData.data.openTime > Date.now()) {
                    setIdEnd(false);
                }
                setResetEnd(true);

            }
        } catch (err) {
            // console.log(messageData.customElem);
        }
    }, [userInfo]);
    // 判断是否是luck是的话
    if (cardData.totalAwardAmount) {
        return <LuckMessage quote={ quote } self={ self } data={ messageData as any }
                            cardData={ cardData }></LuckMessage>;
    }
    return <div className={ classNames("chat-card", {
        quote
    }) }>
        <div className="card-message">
            <div className="card-top">
                <Image src={ replaceOssImage(cardData.gameIconUrl) } className="game-icon"></Image>
                <div className="card-title">
                    <div className="title">{ cardData.gameName }</div>
                    <div className="sub-title">
                        {
                            cardData.issNo
                        }
                    </div>
                </div>
                <div className="remind-time">
                    {
                        isEnd ? resetEnd ? "Prize drawn" : "" : <CountTime remindTime={ drawTime } onEnd={ () => {
                            setIdEnd(true);
                        } }></CountTime>
                    }

                </div>
            </div>
            <div className="card-inner">
                <div className="payment">
                    <div className="payment-text">My Bets</div>
                    <div className="payment-right">
                        <span className="text">
                            Total Payment
                        </span>
                        <span className="price">
                            ₹{ cardData.totalAmount?.toFixed(2) || 0.00 }
                        </span>
                    </div>
                </div>
                {
                    cardData.list && cardData.list.length > 0 && <>
                        <div className={ classNames("content-wapper") }>
                            <div className="content">
                                {
                                    renderCardContent(cardData.gameType || "dice", cardData.list || [])
                                }
                            </div>
                        </div>
                    </>
                }
                <div className="line"></div>
                <ShareFollowed self={ self } data={ cardData } isEnd={ isEnd }/>
            </div>
        </div>
        { !quote && <MessageState data={ messageData } className="message-state__bottom"/> }

    </div>;
}

export default memo(ShareCards);
