export const darkTheme = {
    "--whats-white": "#fff",
    // 模块大标题，重点文字部分
    "--whats-color-t1": "#F9F9FB",
    "--whats-color-t2": "#E5EEF5",
    "--whats-color-t3": "#84969A",
    "--whats-color-t4": "#595959",
    // 主题色
    "--whats-base-color": "#20C164",
    "--whats-bg": "#0B141B",

    // 卡片
    "--whats-card": "#293237",
    // 间隔线
    "--whats-line": "#161F26",
    "--whats-card-line": "rgba(255, 255, 255, 0.20)",
    // 标题栏
    "--whats-title": "#121B22",
    // 聊天气泡
    "--whats-main-bubble": "#005D4B",
    "--whats-sub-bubble": "#1F2C34",

    // 点击效果
    "--whats-active": "#103629",
    // 聊天信息的选择样式
    "--whats-message-select-bg": "rgba(0, 93, 75, 0.4)",
    // 首页底部选择框
    "--home-selectBar-bg": "#121B22",
    // 按钮icon
    "--whats-button-icon": "#0A151B",
    // 顶部logo
    "--whats-chat-logo": "#E5EEF5",
    // 顶部公告的渐变
    "--whats-notice-shadow": "linear-gradient(90deg, rgba(18, 27, 34, 0) 0%, #121B22 70%)",

    // 引用框背景
    "--quoto-bg": "#1E292F",
    // 引用框基础色
    "--quoto-base-color": "var(--whats-base-color)",
    // 引用别人的
    "--quoto-second-color": "#3497F9",
    // 信息内的引用框背景
    "--quoto-info-base-color": "#134431",
    "--quoto-info-second-color": "#1E292F",
    // 弹框底部操作
    "--modal-handler-bg": "#20272B",
    // 群设置
    "--setting-bg": "#000",

    "--role-tag-super-bg": "#103629",
    "--role-tag-super-text": "#D4FDDF",
    "--role-tag-group-bg": "#3F200E",
    "--role-tag-group-text": "#FDE3D4",
    // voice的shadow
    "--voice-shadow": "linear-gradient(90deg, #293237 40%, rgba(40, 51, 50, 0) 100%)",
    "--voice-wave-bar": "#84969A",
    "--voice-wave-progress": "#ABC9C7",

    // 辅助色
    "--secondary-color": "#F15802",
    "--secondary-border-color": "#F15802",
    // 背景相关
    // 背景色
    "--whats-chat-bg": "#F7F4EF",

    // 聊天界面相关
    // 系统消息气泡颜色
    "--system-message-bg": "#283138",
    "--system-message-shadow": "0px 0px 1px 0px rgba(0, 0, 0, 0.60)",
    // 分享卡片的背景色
    "--share-bg": "rgba(255,255,255,0.1)",
    // 跟投弹窗变量
    "--bets-head-bg": "#0B141B",
    "--bets-item-bg": "#121B22",
    // 底部表情框icon选中
    "--expression-active": "#293237",
    "--expression-bg": "#121B22",

    "--recharge-input-bg": "rgba(255, 255, 255, 0.10)",
    "--recharge-input-border": "#84969A",
    "--recharge-card-bg": "rgba(255, 255, 255, 0.10)",
    "--recharge-card-border": "rgba(255, 255, 255, 0.10)",
    "--recharge-card-shadow": "0px 2px 0px 0px #121B22",
    // 预测相关
    // 预测的线条
    "--predict-line": "rgba(255, 255, 255, 0.10)",
    "--predict-bg": "rgba(255, 255, 255, 0.10)",
    "--predict-btn": "#4C515C",
    "--predict-input-bg": "#2E3842",
    "--predict-select-bet": "#005D4B",
    "--predict-select-bet-checked": "#20C164",
    "--predict-table-thead": "#3E464B",
    "--predict-table-even": "#343C41",
    //-----------分割线---------

    "--select-item-color": "rgba(0, 58, 140, 0.4)",
    "--font-color-base": "#fff",
    "--font-size-small": "14px",


    "--bubble-other": "#1F2C34",
    "--bubble-mine": "#003A8C",
    // admin聊天气泡样式
    "--admin-bubble-color": "#F53F3F",
    "--admin-bubble-bg": "#343333",

    "--chat-select-bg": "#121B22",

    "--primary-text-color": "#20C164",
    "--primary-bg-color": "#0B141B",
    "--primary-border-color": "#20C164",


    "--notice-bg-color": "#003A8C",
    "--notice-icon-color": "#EC4E49",


    "--muted-tab-bg": "#121B22",

    "--heart-bg": "rgba(255, 255, 255, 0.60)",


    // person相关
    "--person-filter-bg": "rgba(11, 20, 27, 0.80)",
    // 未读字体颜色
    "--read-text": "#0A151B",

    // 底部输入框颜色
    "--bottom-box-bg": "#0B141B",

};

