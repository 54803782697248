import { Image } from "antd-mobile";
import emptyImage from "@/assets/empty.png";
import cls from "./style.module.scss";
import { HTMLProps } from "react";

interface EmptyProps extends HTMLProps<HTMLDivElement> {
}

export function Empty(props: EmptyProps) {
    return <div className={ cls.empty } {...props}>
        <Image className={ cls["empty-image"] } src={ emptyImage }></Image>
        <div className={ cls["empty-text"] }>All Empty</div>
    </div>;
}

export default Empty;
