import { CustomConversationItem } from "@/store/slice/home";
import {
    NotificationStatus,
} from "@chat/zim-sdk";
import store from "@/store";
export const SUBSCRIBERS_COUNT = 33670;
// 获取未读数量，如果没有传入列表则使用内存里的
export function getVisibleUnReadCount(list?: CustomConversationItem[]) {
    const visibleList: CustomConversationItem[] = list ?? store.getState().home.list;
    return visibleList.reduce((total, item) => {
        if (item.conversationID && item.notificationStatus === NotificationStatus.Notify) {
            return total + (item.unreadMessageCount || 0);
        }
        return total;
    }, 0);
}

