import cls from "./style/dice.module.scss";
import Followed from "./Followed";
import { Image } from "antd-mobile";
import { renderForecastResults } from "@chat/component";
import { ZIMCustomMessage } from "@chat/zim-sdk";
import { getVmSize, parseStr, useCountDown } from "@chat/shared";
import { useAppDispatch, useAppSelector } from "@/store";
import { EventCardItem, EventResult, showHistory } from "@/store/slice/prediction";
import dayjs from "dayjs";
import MessageStatus from "../MessageItem/MessageState";
import { useMemo, useState } from "react";

type PredictiveCardProps = {
    data: ZIMCustomMessage
}

function DicePrediction(props: PredictiveCardProps) {
    const dispatch = useAppDispatch();
    const { data } = props;
    const { self } = useAppSelector(state => state.user);
    const cardData = parseStr<EventCardItem>(data.message);
    const [disabled, setDisabled] = useState(cardData.drawTime < Date.now());
    useCountDown(cardData.drawTime, () => {
        setDisabled(true);
    });
    // return Math.min(1328, Math.max(3, Math.floor(Math.random() * 200)));

    const num = useMemo(() => {
        const diff = Math.max(0, Math.floor((cardData.drawTime - Date.now())));
        // 大于2小时，那么全部已读。否则，1分钟 + 10
        let second = Math.floor(diff / 1000 / 6);
        const count = Math.min(999, second + Math.floor(Math.random() * 99));
        return count
    }, []);
    return (
        <>
            <div className={ cls.container } style={ { opacity: disabled ? 0.3 : 1 } }
                 onClick={ () => dispatch(showHistory(cardData)) }>
                <div className={ cls.detail }>
                    <Image src={ cardData.gameIcon } className={ cls.pic }/>
                    <div className={ cls.detail__info }>
                        <div className={ cls.name }>{ cardData.gameName }</div>
                        <div className={ cls.id }>{ cardData.issueNo }</div>
                    </div>
                    <div className={ cls.detail__time }>
                        <div className={ cls.drawText }>Draw time</div>
                        <div className={ cls.drawTime }>{ dayjs(cardData.drawTime).format("DD-MM-YY hh:mm:ss") }</div>
                    </div>
                </div>
                <div className={ cls.forecast }>
                    <div className={ cls.forecast__item }>
                        <div className={ cls.forecast__item__title }>forecast accuracy:</div>
                        <div className={ cls.forecast__item__value }>{ Math.floor((cardData.forecastRate * 100)) }%
                        </div>
                    </div>
                    <div className={ cls.forecast__item }>
                        <div className={ cls.forecast__item__title }>forecast results:</div>
                        <div className={ cls.forecast__item__result }>
                            {
                                // 通过不同的游戏类型，展示不同的预测结果
                                renderForecastResults({
                                    result: parseStr<EventResult>(cardData.forecastResults),
                                    gameType: cardData.gameType
                                })
                            }

                        </div>
                    </div>
                    <div className={ cls.line }></div>
                    <Followed num={ num } disabled={ disabled }
                              self={ data.senderUserID === self.userID } data={ cardData }/>
                </div>
            </div>
            <MessageStatus style={ { marginTop: getVmSize(4) } } data={ data }/>
        </>
    );
}

export default DicePrediction;
