import { createSlice } from "@reduxjs/toolkit";
import { parseStr } from "@chat/shared";

export type EventResult = {
    bigOrSmall: 1 | 2 | 0;  // * 1.大  2.小  0.豹子
    oddOrEven: 1 | 2 | 0;  // * 1.单odd  2.双  0.豹子
    totalCount: number;

    // dice 3d
    one: number;
    two: number;
    three: number
    // color
    number: number;
    oneColor: "r";
    twoColor: "v";
    // threeColor: "g";
}
export type EventCardItem = {
    sourceId: number;
    gameType: "dice" | "3digit" | "color";
    forecastRate: number;
    groupId: string;
    drawTime: number;
    packageId: number;
    updateTime: number;
    issueNo: number;
    gameIcon: string;
    cycle: number;
    forecastResults: string;
    gameName: string;
    createTime: number;
    id: number;
    status: number
}
export type ForecastDataItem = Omit<EventCardItem, "forecastResults"> & { forecastResults: EventResult }
const initialState = {
    forecastVisible: false,
    historyVisible: false,
    forecastData: {
        forecastResults: {} as EventResult
    } as ForecastDataItem
};

export const predictionSlice = createSlice({
    name: "prediction",
    initialState,
    reducers: {
        showForecast(state, action) {
            state.forecastData = {
                ...action.payload,
                forecastResults: parseStr<EventResult>(action.payload.forecastResults)
            };
            state.forecastVisible = true;
        },
        closeForecast(state) {
            state.forecastVisible = false;
        },
        showHistory(state, action) {
            state.historyVisible = true;
            state.forecastData = {
                ...action.payload,
                forecastResults: parseStr<EventResult>(action.payload.forecastResults)
            };
        },
        closeHistory(state) {
            state.historyVisible = false;
        },
        reset() {
            return initialState;
        }
    }
});

export const {
    showForecast,
    closeForecast,
    showHistory,
    closeHistory,
    reset
} = predictionSlice.actions;
export default predictionSlice.reducer;
