import { Image } from "antd-mobile";
import React, { memo, useEffect, useState } from "react";
import { CardTypeData } from "@/pages/Chat/feature/ShareCards";

type ShareFollowedProps = {
    data: CardTypeData;
    num?: number
}
const avatarList = Array(80).fill(0).map((_item, index) => {
    return require(`@/assets/avatar/image_${ 1 + index }.webp`);
});


const AvatarGroup = (props: ShareFollowedProps) => {
    const { num } = props;
    const [list, setList] = useState<string[]>([]);
    useEffect(() => {
        // const avatarIndex = (data.openTime || Date.now()) % 80;
        const avatarIndex = Math.floor(Math.random() * 75);
        const newList = avatarList.slice(avatarIndex, avatarIndex + 3);
        setList(newList);
    }, []);
    return <div className="my-avatar-group">
        <div className="avatar-group-user">
            {
                num ? <span>{ num } people</span> : null
            }

            {
                list.map((item, index) => {
                    return <Image src={ item } key={ index } className="avatar-image"></Image>;
                })
            }
        </div>
    </div>;
};

export default memo(AvatarGroup);
