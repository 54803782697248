import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/store";
import {
    addNewMessage,
    deleteMessageFormStore,
    fetchHistoryList,
    fetchRechargeData,
    resetChat,
    setChatMessage,
    setConversationDetail,
    setGroupGameList,
    setHistoryVisible,
    setMessageList,
    setSelfInfo,
    setSelfMuted,
    updateGroupInfo,
    updateMessageReceipt,
    updateSingleInfo
} from "@/store/slice/chat";
import { showDismissVisible } from "@/store/slice/common";
import ImageViewer from "@/components/ImageViewer";
import store2 from "store2";
import { useNavigate, useParams } from "react-router-dom";
import {
    ConversationType,
    enterConversation as enterSDKConversation,
    leaveConversation as leaveSDKConversation,
    enterRoom as enterSDKRoom,
    decorationMessage,
    ZIMCustomMessage,
    MessageReceiptStatus,
    MessageType, createTextMessage
} from "@chat/zim-sdk";
import {
    getUserInfo,
    leaveRoom,
    RoomEvent,
    getGroupUserInfo,
    normalizeHistoryMessage,
    getRoomMemberCount,
} from "@chat/zim-sdk";
import {
    emitter,
    EventType,
    isVisitorUser,
    sendRoomMemberCount,
    toast,
    useListenMessage,
    visitorStore, WaitingQueue
} from "@/utils";
import { reset } from "@/store/slice/personal";
import { getAdminList, getUserGroupInfo, groupUserLastMsg } from "@/apis/room";
import { setAdminList, reset as groupReset } from "@/store/slice/groupSetting";
import { reset as predictionReset } from "@/store/slice/prediction";
import { cancelRaf, raf, useAppSearchParams, useChatMode } from "@chat/shared";
import { resetGif } from "@/store/slice/git";
import { useLatest } from "ahooks";
import useSendMessage from "@/pages/Chat/feature/hooks/useSendMessage";
import { useRafState } from "ahooks";

const queue = new WaitingQueue();
let rafId = -1;

function useInit() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { conversationType = "", conversationID = "" } = useParams();
    const [roomMemberCount, setRoomMemberCount] = useRafState(0); // 在线人数
    const { list } = useAppSelector(state => state.chat);
    const latestList = useLatest(list);
    const { logged } = useAppSelector(state => state.user);
    const { sendMessage } = useSendMessage();
    const amount = useAppSearchParams("amount");
    // normal正常的群聊 inside内嵌在游戏内部
    const { chatMode } = useChatMode();
    useEffect(() => {
        if (+conversationType === ConversationType.Room) {
            sendRoomMemberCount(roomMemberCount, conversationID);
        }
    }, [roomMemberCount, conversationType, conversationID]);
    // 加入房间，退出房间都调用一次，用来标记未读和订阅
    const signRoom = useCallback((groupId: string, imUserId: string, lastMsgId: string) => {
        // 如果是游客不调用
        if (isVisitorUser()) {
            return;
        }
        groupUserLastMsg({
            groupId,
            imUserId,
            msgId: lastMsgId
        }).then(res => {
            console.log(res, "lastMsg");
        });
    }, []);

    // 获取当前群的管理列表
    const getMemberList = async () => {
        if (+conversationType !== ConversationType.Room) {
            return;
        }
        try {
            const res = await getAdminList({ groupId: conversationID });
            const adminList: string[] = res.data.map((item: any) => item.imUserId);
            dispatch(setAdminList(adminList));
        } catch (err) {
            console.error(err);
        }
    };
    const enterConversation = (userID: string) => {
        // 更新个人详情
        if (+conversationType === ConversationType.Peer) {
            getUserInfo(conversationID, true).then(res => {
                dispatch(updateSingleInfo(res.user));
                enterPeerOrGroupRoom(conversationID);
            });
            return () => {
                if (visitorStore.isPermanentUser(conversationID)) {
                    visitorStore.leaveConversation(conversationID, latestList.current);
                }
                leaveSDKConversation(conversationID);
            };
        }
        // 在线聊天室
        if (+conversationType === ConversationType.Room) {
            // 获取房间信息
            getUserGroupInfo({ groupId: conversationID, imUserId: userID }).then(res => {
                const { data, code } = res;
                if (code === 0) {
                    if (!data.imGroupVo.groupId) {
                        // 群被解散
                        dispatch(showDismissVisible());
                        return;
                    }
                    const user = getGroupUserInfo(data, store2.get("userID"));
                    // todo 公开房或者已经输入密码，那么加入房间
                    const canJoin = () => {
                        // 如果是内嵌，直接进入
                        if (chatMode === "inside") {
                            return true;
                        }
                        if (user.block) {
                            toast("You have been blocked", "error");
                            navigate("/home", { replace: true });
                            return false;
                        }
                        // 频道或者非密码房
                        return [1, 3].includes(data.imGroupVo.groupType) || data.fillPass;

                    };
                    if (canJoin()) {
                        const { groupId, groupName } = data.imGroupVo;
                        // 加入房间
                        enterRoom({ roomName: groupName, roomID: groupId }, userID).then(res => {
                            // 查询当前用户的人数
                            getRoomMemberCount(groupId).then(roomData => {
                                setRoomMemberCount(roomData.count);
                            });
                            // dispatch(fetchHistoryList(conversationID));
                        });
                        // 获取管理员列表
                        getMemberList();
                        // 获取用户数据
                        dispatch(setSelfInfo(user));
                        // 更新群信息，
                        dispatch(updateGroupInfo(data.imGroupVo));
                        dispatch(setGroupGameList(data.imUserGameNoticeVos));
                        // dispatch(fetchHistoryList(conversationID));
                        // 更新历史消息
                        if (data.lastMsgList) {
                            let historyList = data.lastMsgList.map(normalizeHistoryMessage);
                            if (chatMode === "inside") {
                                historyList = historyList.filter(item => {
                                    return [MessageType.Text, MessageType.Custom].includes(item.type as any);
                                });
                            }
                            dispatch(setMessageList(decorationMessage(historyList as ZIMCustomMessage[], conversationID)));
                            signRoom(groupId, userID, historyList[historyList.length - 1]?.messageID);
                            setTimeout(() => {
                                emitter.emit("CHAT_LIST_SCROLL_TO_BOTTOM", { behavior: "auto" });
                            }, 50);
                        }

                    } else {
                        console.log("need get password");
                    }
                } else {
                    toast(res.msg || res.message, "error");
                }
            });
            if (chatMode === "normal") {
                // 普通模式开启历史
                dispatch(setHistoryVisible(true));
            }
            return () => {
                signRoom(conversationID, userID, latestList.current[latestList.current.length - 1]?.messageID);
                leaveRoom(conversationID);
            };
        }
    };
    // 根据id来判断需要加入的聊天房间
    const enterPeerOrGroupRoom = (conversationID: string) => {
        // 更新chat的聊天历史信息
        enterSDKConversation(conversationID, {
            onMessages: (list) => {
                // 更新信息
                list.forEach(message => {
                    dispatch(addNewMessage(message));
                });
                emitter.emit("CHAT_LIST_SCROLL_BY_SHOW_BTN");
            },
            onMessageReceiptChanged(infos) {
                infos.forEach(item => {
                    dispatch(updateMessageReceipt(item));
                });
            }
        }).then(({ conversation }) => {
            // // 更新会话详情
            dispatch(setConversationDetail(conversation));
            // 更新历史消息，如果是匿名用户，需要更新内容
            dispatch(fetchHistoryList(conversationID));

        }).catch(err => {
            // 这里没有加入会话
            if (visitorStore.isPermanentUser(conversationID)) {
                // 常驻客服加入聊天室
                visitorStore.entryConversation(conversationID);
                const history = visitorStore.getHistoryList(conversationID);
                history.filter((item: any) => item.type !== MessageType.TimeTip).forEach(message => {
                    dispatch(addNewMessage(message));
                });
            }
            console.error(err, `current ${ conversationID } conversation is not open`);
        });
    };
    const enterRoom = async (roomInfo: {
        roomID: string;
        roomName: string;
    }, userID: string) => {

        return enterSDKRoom(roomInfo, userID, {
            onMessages: (list) => {
                // 更新信息
                list.forEach(message => {
                    const newMessage = {
                        ...message,
                        receiptStatus: MessageReceiptStatus.Done
                    };
                    if (chatMode === "inside") {
                        if ([MessageType.Text, MessageType.Custom].includes(message.type as any)) {
                            queue.enqueue(newMessage);
                        }
                    } else {
                        queue.enqueue(newMessage);
                    }
                });
                // 需要对数据处理，可能是自定义的系统消息，这个不需要添加到应用中
            },
            onMutedMessage: (data) => {
                if (chatMode === "inside") return;
                if (data.muteExpiredTime > Date.now()) {
                    toast("You have been muted.", "error");
                } else {
                    toast("You can speak now.");
                }
                dispatch(setSelfMuted(data.muteExpiredTime));
            },
            onMessageDelete: data => {
                dispatch(deleteMessageFormStore(data.deleteMessageList));
            },
            onBlockMessage: () => {
                if (chatMode === "inside") return;
                toast("You have been blocked by the administrator", "error");
                navigate("/home", { replace: true });
            },
            onRoomStateChanged: data => {
                // 内嵌处理
                if (chatMode === "inside") return;
                // 房间状态变更
                // 需要通过类型去判断
                switch (data.event) {
                    case RoomEvent.RoomNotExist:
                        toast("Room does not exist", "error");
                        dispatch(resetChat());
                        navigate("/home", { replace: true });
                        break;
                    case RoomEvent.Success:
                        console.log("room Success");
                        break;
                    case RoomEvent.KickedOut:
                        console.log("room-user KickedOut!");
                        toast("You have been kicked out of this room.", "error");
                        dispatch(resetChat());
                        navigate("/home", { replace: true });
                        break;
                    case RoomEvent.KickedOutByOtherDevice:
                        console.log("KickedOutByOtherDevice");
                        dispatch(resetChat());
                        toast("Logged out due to multiple device logins", "error");
                        navigate("/home", { replace: true });
                        break;
                }
            },
            onRoomInfoChanged() {
                // 房间信息变更
                getUserGroupInfo({ groupId: roomInfo.roomID, imUserId: userID }).then(res => {
                    if (res.code === 0) {
                        dispatch(updateGroupInfo(res.data.imGroupVo));
                    }
                });
            },
            onRoomDismiss() {
                leaveRoom(roomInfo.roomID);
                dispatch(showDismissVisible());
            },
            onRoomMemberLeft(data) {
                // 有人离开房间

                setRoomMemberCount(v => v - data.memberList.length);
            },
            onRoomMemberJoined(data) {
                // 有人加入房间
                setRoomMemberCount(v => v + data.memberList.length);
            },
        });
    };
    useEffect(() => {
        if (+conversationType === ConversationType.Peer && !isVisitorUser()) {
            dispatch(fetchRechargeData());
        }
        if (+conversationType === ConversationType.Peer && +amount) {
            dispatch(setChatMessage(`I want to recharge ${ amount }`));
        }
        const leaveConversation = enterConversation(store2.get("userID"));
        return () => {
            ImageViewer.clear(); // 清除已有的图片预览
            // 退出聊天窗口，需要重置数据
            dispatch(resetChat());
            dispatch(reset());
            dispatch(resetGif());
            dispatch(groupReset());
            dispatch(predictionReset());
            leaveConversation?.();
        };
    }, [conversationID, conversationType, logged, amount]);
    const get3DLocalMessage = (conversationID: string, source: any) => {
        const userId = store2.get("userID");
        const sourceCopy = JSON.parse(JSON.stringify(source));
        sourceCopy.conversationID = conversationID;
        sourceCopy.senderUserID = userId;
        sourceCopy.message = JSON.stringify({
            ...sourceCopy.message,
            groupId: conversationID,
            imUserId: userId,
            content: JSON.stringify(sourceCopy.message.content),
        });
        return sourceCopy;
    };
    useListenMessage((payload) => {
        if (payload.event === EventType.MESSAGE) {
            // 发送消息
            const text = payload.data.message;
            if (text) {
                sendMessage({
                    conversationID,
                    message: createTextMessage(payload.data.message || "")
                });
            }
        }
        if (payload.event === EventType.IM_LOCAL_MESSAGE) {
            // 发送消息
            const localMessage = payload.data.sharedData;
            if (localMessage) {
                // 本地插入的数据不存在群id和userid，需要自己添加
                const message = get3DLocalMessage(conversationID, localMessage);
                queue.enqueue({
                    ...message,
                    selfPush: true
                });
            }
        }
    });
    const pushGameMessage = () => {
        rafId = raf(() => {
            if (!queue.isEmpty()) {
                const message = queue.dequeue();
                dispatch(addNewMessage(message));
                emitter.emit("CHAT_LIST_SCROLL_BY_SHOW_BTN");
                if (message.selfPush) {
                    emitter.emit("CHAT_LIST_SCROLL_TO_BOTTOM", { behavior: "smooth" });
                }
            }
            pushGameMessage();
        });
    };
    useEffect(() => {
        pushGameMessage();
        const visibilitychange = () => {
            if (document.visibilityState === "visible") {
                const list = queue.value;
                list.forEach(message => {
                    dispatch(addNewMessage(message));
                })
                emitter.emit("CHAT_LIST_SCROLL_BY_SHOW_BTN");
                queue.clear();
            }
        }
        document.addEventListener("visibilitychange", visibilitychange)
        return () => {
            cancelRaf(rafId);
            queue.clear();
            document.removeEventListener("visibilitychange", visibilitychange)
        };
    }, []);
    return {
        chatMode
    };
}

export default useInit;
