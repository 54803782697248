import { getVideoSnShotFile, ZIMVideoMessage } from "@chat/zim-sdk";
import { useEffect, useState } from "react";

const useFirstFrameView = (data: ZIMVideoMessage) => {
    const [videoFirstFrameDownloadUrl, setVideoFirstFrameDownloadUrl] = useState(data.videoFirstFrameDownloadUrl);
    useEffect(() => {
        // 判断是否是历史消息，防止和本地上传冲突
        if (!data.videoFirstFrameDownloadUrl && ((data as any).history)) {
            getVideoSnShotFile(data.fileDownloadUrl).then(res => {
                setVideoFirstFrameDownloadUrl(res.url);
            });
        }
    }, [data.fileDownloadUrl]);
    return {
        videoFirstFrameDownloadUrl
    };
};
export default useFirstFrameView;
