import MessageState from "@/pages/Chat/feature/MessageItem/MessageState";
import { useAppDispatch } from "@/store";
import { showVideoData } from "@/store/slice/chat";
import { getVmSize, formattedTime } from "@chat/shared";
import { ZIMVideoMessage, MessageStatus } from "@chat/zim-sdk";
import LoadingBall from "@/pages/Chat/feature/MessageItem/LoadingMedia";
// import { useRef } from "react";
// import ReactPlayer from "react-player";
import useFirstFrameView from "@/pages/Chat/feature/hooks/useFirstFrameView";

const VideoIcon = (props: any) => {
    const { className } = props;
    return <div className={ className }>
        <svg viewBox="0 0 16 17" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.6667 4.86133L12 7.11133V9.27799L14.6667 11.528V4.86133Z"
                  fill="white"/>
            <path d="M11.9998 12.8617V3.52832H1.33301L1.33313 12.8617H11.9998Z" stroke="white" strokeWidth="1.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </div>;


};
type VideoMessageProps = {
    data: ZIMVideoMessage
}
export default function VideoMessage(props: VideoMessageProps) {
    const { data } = props;
    const dispatch = useAppDispatch();
    const imageHeight = data.videoFirstFrameHeight || 0;
    const imageWidth = data.videoFirstFrameWidth || 0;
    const snapshotMaxHeight = 351;
    const snapshotMaxWidth = 267;
    const minHeight = Math.min(snapshotMaxWidth, imageWidth) * (imageHeight / imageWidth) || 0;
    const adaptedHeight = Math.min(minHeight, snapshotMaxHeight);
    const adaptedWidth = Math.min(imageWidth, snapshotMaxWidth);
    const isSending = data.sentStatus === MessageStatus.Sending;
    const viewStyle = {
        width: getVmSize(adaptedWidth),
        height: getVmSize(adaptedHeight),
    };
    // const player = useRef<ReactPlayer>(null);
    const { videoFirstFrameDownloadUrl } = useFirstFrameView(data);
    const gotoVideo = () => {
        if (isSending) return;
        dispatch(showVideoData(data));
    };
    return <div className="video-message">
        <div onClick={ gotoVideo } className="video-message-snap">
            {/*<ReactPlayer*/ }
            {/*    url={ data.fileDownloadUrl || "" }*/ }
            {/*    muted*/ }
            {/*    style={ { borderRadius: getVmSize(8) } }*/ }
            {/*    width={ viewStyle.width }*/ }
            {/*    height={ viewStyle.height }*/ }
            {/*    onClickPreview={ gotoVideo }*/ }
            {/*    ref={ player }*/ }
            {/*    volume={ 0 }*/ }
            {/*>*/ }
            {/*</ReactPlayer>*/ }
            <img alt="" src={ videoFirstFrameDownloadUrl } style={ {
                width: viewStyle.width,
                height: viewStyle.height,
                background: "#ccc"
            } }>
            </img>
        </div>
        <LoadingBall
            data={ data }
            style={ { minWidth:viewStyle.width, minHeight:viewStyle.height } }
        ></LoadingBall>
        <div className="video-message-shadow">
            <div className="video-message-duration">
                <VideoIcon className="video-message-icon"></VideoIcon>
                <span>{ formattedTime(data.videoDuration * 1000).join(":") }</span>
            </div>
            <MessageState className="video-message-time" data={ data }/>
        </div>
    </div>;
}
