import { ReactNode } from "react";
import cls from "@/pages/Chat/feature/Modal/PredictionModal/style.module.scss";
import Empty from "@/components/Empty";
import { LoadingSpin } from "@/components/Loading";
import { useAppSelector } from "@/store";

export type ColumnProps = {
    title: ReactNode
    dataIndex: string
    key: string
    render?: (text: any, record: any) => ReactNode
}
type TableProps = {
    dataSource: any[];
    columns: ColumnProps[];
    rowKey?: string;
    rowClick?: (record: any) => void;
    loading?: boolean;
}


function PredictionTable(props: TableProps) {
    const { columns, dataSource, rowKey, loading } = props;
    const theme = useAppSelector(state => state.common.theme);
    return <div className={ cls.table_container }>
        <table className={ cls.table }>
            <thead>
            <tr>
                {
                    columns.map(col => {
                        return <td className={ cls.cell } key={col.key}>
                            { col.title }
                        </td>;
                    })
                }
            </tr>
            </thead>
            <tbody>
            {
                dataSource.map((item, idx) => {
                    return <tr key={ rowKey ? item[rowKey] : idx }>
                        {
                            columns.map((col, colIdx) => {
                                return <td className={ cls.cell } key={ col.key || colIdx } onClick={ () => {
                                    props.rowClick?.(item);
                                } }>
                                    { col.render ? col.render(item[col.dataIndex], item) : item[col.dataIndex] }
                                </td>;
                            })
                        }
                    </tr>;
                })
            }
            </tbody>
        </table>
        {
            loading ? <div style={ { marginTop: "50px" } }>
                <LoadingSpin color={ theme.value["--whats-base-color"] }/>
            </div> : dataSource.length === 0 && <Empty/>
        }
    </div>;

}

export default PredictionTable;
