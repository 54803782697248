import { Ellipsis, Image } from "antd-mobile";
import PersonalCard from "./PersonalCard";
import { authLogin, isDefaultUser, sendStartGameMessage } from "@/utils";
import { getVmSize, replaceOssImage } from "@chat/shared";
import { useAppSelector } from "@/store";
import { LoadingSpin } from "@/components/Loading";
import Empty from "@/components/Empty"

function FavoriteGames() {
    const favoriteGameResList = useAppSelector(state => state.personal.statistics.favoriteGameResList);
    const loading = useAppSelector(state => state.personal.loading);
    const theme = useAppSelector(state => state.common.theme);
    const startGame = (gameId: number) => {
        if (authLogin() && !isDefaultUser()) {
            sendStartGameMessage(gameId);
        }
    };
    const renderGame = () => {
        return favoriteGameResList.map(item => {
            return <div className="favorite-game-item" key={ item.gameId }
                        onClick={ () => startGame(item.gameId) }>
                <div className="favorite-game-item-left">
                    <Image src={ replaceOssImage(item.gamePicUrl) } className="image"></Image>
                    <Ellipsis className="title" direction="end" rows={ 2 } content={ item.gameName }/>
                </div>
                <div className="prize">
                    <div className="text">Winning Prize</div>
                    <Ellipsis className="price" direction="end" content={ "₹" + item.winAmount }/>
                </div>
            </div>;
        });
    };
    return <PersonalCard title="Favorite games" style={ { marginTop: getVmSize(12) } }>
        <div className="favorite-game">
            {
                loading ?
                    <LoadingSpin color={theme.value["--whats-base-color"]}/> :
                    favoriteGameResList && favoriteGameResList.length ?
                        renderGame() :
                        <Empty/>
            }
        </div>
    </PersonalCard>;
}

export default FavoriteGames;
