import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";
import { ThemeVariable } from "@/theme";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
    theme?: ThemeVariable;
}

function ThemeIcon(props: IconProps) {
    const { theme, ...attrs } = props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, type] = useThemeColor(theme);
    const color = type === "dark" ? "#E5EEF5" : "#5F6975";

    return <span role="img" { ...attrs }>
<svg  viewBox="0 0 12 12" fill="none">
    <rect x="1" y="5" width="10" height="2" rx="1" fill={color}/>
</svg>
    </span>;
}

export default memo(ThemeIcon);
