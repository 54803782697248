import { Image } from "antd-mobile";
import React, { TouchEvent, useRef, useState } from "react";
import classnames from "classnames";
import classNames from "classnames";
import TextMessage from "@/pages/Chat/feature/MessageItem/TextMessage";
import QuoteMessage from "@/pages/Chat/feature/MessageItem/quoteMessage";
import { addSelectList, deleteMessageList, setSelectState, updateMessage } from "@/store/slice/chat";
import { useAppDispatch, useAppSelector } from "@/store";
import PictureMessage from "@/pages/Chat/feature/MessageItem/PictureMessage";
import VoiceMessage from "@/pages/Chat/feature/MessageItem/voiceMessage";
import messageFaildedIcon from "@/assets/svg/message-failed.svg";
// import messageSendingIcon from "@/assets/svg/message-sending.png";
import { authLogin, isVisitorUser } from "@/utils";
import { parseStr, replaceOssImage, useChatMode } from "@chat/shared";
import { openPersonVisible } from "@/store/slice/personal";
import defaultPersonPic from "@/assets/svg/default-person.svg";
import MessageArrow from "@/components/themeSvg/MessageArrow";
import VideoMessage from "@/pages/Chat/feature/MessageItem/VideoMessage";
import supportJson from "@/assets/lottie/support.json";
import adminJson from "@/assets/lottie/admin.json";
import {
    ChatMessageItem,
    CustomType,
    getConversationType,
    ZIMCustomMessage,
    ZIMTextMessage
} from "@chat/zim-sdk";
import {
    ConversationType,
    MessageStatus,
    MessageType,
    useMessageItemClass,
    ZIMAudioMessage,
    ZIMImageMessage,
    ZIMVideoMessage,
    useUserInfo
} from "@chat/zim-sdk";
import useSendMessage from "@/pages/Chat/feature/hooks/useSendMessage";
import CardMessage from "@/pages/Chat/feature/MessageItem/CardMessage";
import ShareCards from "@/pages/Chat/feature/ShareCards";
import PredictionCard from "@/pages/Chat/feature/Prediction/PredictionCard";
import useIdentity from "@/hooks/useIdentity";
import Lottie from "@/components/Lottie";
import BetAlertMessage, { getBetData } from "@/pages/Chat/feature/MessageItem/BetAlertMessage";

type ChatItem = {
    data: ChatMessageItem
}

function ChatItem(props: ChatItem) {
    const {
        data,
    } = props;
    const dispatch = useAppDispatch();
    const { userInfo } = useUserInfo(data.senderUserID);
    const { self: selfInfo } = useAppSelector(state => state.user);
    const { sendMessage } = useSendMessage();
    const {
        isSelect,
        selectList,
    } = useAppSelector(state => state.chat);
    const { adminList, adminSetup } = useAppSelector(state => state.groupSetting);
    const theme = useAppSelector(state => state.common.theme);
    const [layoutCls] = useMessageItemClass(data);
    const [resendLoading, setResendLoading] = useState(false);
    const touchTimer = useRef<NodeJS.Timeout>();
    const longPress = useRef(false);
    const chatRowRef = useRef<HTMLDivElement>(null);
    const exData = userInfo?.extendedData;
    const [touch, setTouch] = useState({
        touchX: 0,
        touchY: 0,
    });
    // 获取是不是管理员
    let adminData = adminList.find(item => item === data.senderUserID);
    const isMain = data.isMergeMessage && exData?.userType !== "bot";
    const self = selfInfo.userID === data.senderUserID;
    const { isIdentity } = useIdentity(data.senderUserID);
    const { chatMode } = useChatMode();
    const renderAvatar = () => {
        if (self) return null;
        if (isMain) {
            return <div className="chat-avatar"></div>;
        }
        const faceUrl = replaceOssImage(userInfo?.userAvatarUrl) + "?x-oss-process=image/resize,m_lfit,w_56";
        return <Image
            className="chat-avatar"
            placeholder={ <Image src={ defaultPersonPic }/> }
            fallback={ <Image src={ defaultPersonPic }/> }
            src={ faceUrl }
            onContainerClick={ () => {
                if (chatMode === "inside") return;
                if (exData?.userType === "bot") return;
                if (data.conversationType === ConversationType.Peer) return;
                if (!authLogin()) return;
                if (adminData || isIdentity) return; // 客服和管理员也无法点开
                dispatch(openPersonVisible({ userId: data.senderUserID, groupId: data.conversationID }));
            } }></Image>;
    };
    const renderNickname = () => {
        // 如果是自己的信息 不显示
        if (self) return null;
        if (data.type === MessageType.Custom && (data as any).subType === CustomType.BetAlert) {
            const alertData = getBetData(data.message as string);
            if (+alertData.chatType === 2) return null;
        }
        const exData = userInfo?.extendedData;
        const getVipIcon = () => {
            if (getConversationType(data.conversationID) === ConversationType.Peer) return null;
            if (isIdentity || !!adminData || !adminSetup) return null;
            if (exData?.userType === "bot") return null;
            return <div className={ `vip${ exData?.level || 0 }` }/>;
        };
        const getotherIcon = () => {
            if (getConversationType(data.conversationID) === ConversationType.Peer) return null;
            if (isIdentity) return <div className={ "message-nickname-support" }>
                <Lottie loop={ 2 } data={ supportJson } className={ "message-nickname-support-icon" }/>
                <span>Support</span>
            </div>;
            if (!!adminData) return <div className={ "message-nickname-support" }>
                <Lottie loop={ 2 } data={ adminJson } className={ "message-nickname-support-icon" }/>
                <span>Admin</span>
            </div>;
        };
        // 客服也不展示，目前单聊不展示
        // 判断是否是管理员，管理员没有VIP，单独图标
        return <div className={ classNames("message-nickname", { "message-nickname-me": self }) }>
            {/*如果是单聊，并且这个人是客服，不显示vip*/ }
            {
                getVipIcon()
            }

            <span className="name">
            { self ? "Me" : userInfo?.userName }
            </span>
            {
                getotherIcon()
            }
            {/*<MessageState data={ data }></MessageState>*/ }
        </div>;
    };

    // 判断是否被选中
    const iscurrentSelect = isSelect && selectList.find(item => item.messageID === data.messageID);
    const renderMessageContent = () => {
        // ShareScratchMessage
        // return <DicePrediction/>
        switch (data.type) {
            case MessageType.Text:
                const TextCom = data.repliedInfo ? QuoteMessage : TextMessage;
                return <div className="message-content">
                    <TextCom data={ data as ZIMTextMessage }></TextCom>
                </div>;
            case MessageType.Custom:
                const customMessage = data as ZIMCustomMessage;
                if (customMessage.subType === CustomType.Card) {
                    return <div className="message-content">
                        <CardMessage data={ customMessage }/>
                    </div>;
                }
                if (customMessage.subType === CustomType.PredictiveEvent) {
                    return <div className="message-content">
                        <PredictionCard data={ customMessage }/>
                    </div>;
                }
                if (customMessage.subType === CustomType.BetAlert) {
                    return <div className="message-content">
                        <BetAlertMessage data={ customMessage }></BetAlertMessage>
                    </div>;
                }
                return <div className="message-content">
                    <ShareCards self={ self } data={ customMessage }></ShareCards>
                </div>;
            case MessageType.Image:
                return <PictureMessage data={ data as ZIMImageMessage }></PictureMessage>;
            case MessageType.Audio:
                return <div className="message-content">
                    <VoiceMessage data={ data as ZIMAudioMessage }></VoiceMessage>
                </div>;
            case MessageType.Video:
                return <div className="message-content">
                    <VideoMessage data={ data as ZIMVideoMessage }/>
                </div>;
            default:
                return <div className="message-content">
                    <div>error type</div>
                </div>;
        }

    };
    const handlerTouchStart = (e: TouchEvent<HTMLDivElement>) => {
        // e.preventDefault();
        const touchX = e.targetTouches[0].screenX;
        const touchY = e.targetTouches[0].screenY;
        setTouch({
            touchX,
            touchY
        });
        if (touchTimer.current) {
            clearTimeout(touchTimer.current);
        }
        touchTimer.current = setTimeout(() => {
            if (isVisitorUser()) {
                return;
            }
            if (chatMode === "inside") return;
            dispatch(setSelectState(true));
            dispatch(addSelectList(data));
            longPress.current = true;
        }, 500);
    };
    const handlerTouchMove = (e: TouchEvent<HTMLDivElement>) => {
        const moveX = e.targetTouches[0].screenX;
        const moveY = e.targetTouches[0].screenY;
        // 解决vivo机型，手指没有move，touchmove事件仍然会调用而导致setTimeout被clear
        if (touch.touchX !== moveX || touch.touchX !== moveY) {
            // 手指滑动，清除定时器，中断长按逻辑
            if (touchTimer.current) {
                clearTimeout(touchTimer.current);
            }
        }
    };
    const handlerTouchEnd = () => {
        // 清除定时器，结束长按逻辑
        if (touchTimer.current) {
            clearTimeout(touchTimer.current);
        }
        if (!longPress.current) {
            if (isSelect) {
                // 进入seleted状态
                dispatch(addSelectList(data));
            }

        } else {
            // 长按
        }
        // 若手指离开屏幕，时间小于我们设置的长按时间，则为点击事件
        longPress.current = false;
    };
    // 重发信息
    const resend = async (msg: ChatMessageItem) => {
        if (resendLoading) return;
        setResendLoading(true);
        try {
            dispatch(deleteMessageList({
                messageList: [msg],
                conversationID: msg.conversationID,
                isAlsoDeleteServerMessage: false
            }));
            const newMesage = JSON.parse(JSON.stringify(msg));
            delete newMesage.messageID;
            delete newMesage.localMessageID;
            await sendMessage({
                conversationID: newMesage.conversationID,
                message: newMesage
            });
        } catch (err) {
            dispatch(updateMessage({
                ...msg,
                sentStatus: MessageStatus.Failed
            }));
        } finally {
            setResendLoading(false);
        }
    };
    const renderStatusIcon = () => {
        switch (data.sentStatus) {
            // case MessageStatus.Sending:
            //     return <div className="message-icon message-icon-sending">
            //         <img alt="" src={ messageSendingIcon }/>
            //     </div>;
            case MessageStatus.Failed:
                return <div className="message-icon" onClick={ () => resend(data) }>
                    <img alt="" src={ messageFaildedIcon }/>
                </div>;
            default:
                return null;
        }
    };
    const renderSystemMessage = () => {
        // 判断是否是预测信息，如果是的话，插入一条提示消息
        if (data.type === MessageType.Custom && (data as any).subType === CustomType.PredictiveEvent) {
            const text = "*Predictions are for reference only. Bet cautiously";
            return <div className="system-box">
                <div className="system-text">{ text }</div>
            </div>;
        }
        return null;
    };
    const getArrowColor = () => {

        if (data.type === MessageType.Custom && (data as any).subType === CustomType.BetAlert) {
            const alertData = parseStr<any>(data.message as any);
            return +alertData.chatType === 2 ? "#FCC8CB" : "#FDE1B8";
        }
        return self ? theme.value["--whats-main-bubble"] : theme.value["--whats-sub-bubble"];

    };
    return <>
        <div
            ref={ chatRowRef }
            className={ classnames("chat-row", {
                selected: iscurrentSelect,
                isMain
            }) }
            data-key={ data.messageID }
            onTouchStart={ handlerTouchStart }
            onTouchMove={ handlerTouchMove }
            onTouchEnd={ handlerTouchEnd }
        >
            <div
                className={ classnames("chat-row-container", { self, selected: iscurrentSelect, admin: !!adminData }) }>
                <div className="chat-row-inner">
                    {
                        renderAvatar()
                    }
                    <div
                        className={ classnames("message-layout") }>
                        {
                            renderStatusIcon()
                        }
                        <div
                            className={ classnames("message", layoutCls) }>
                            {
                                renderNickname()
                            }
                            {
                                renderMessageContent()
                            }
                            <MessageArrow className="card-arrow" self={ self }
                                          color={ getArrowColor() }></MessageArrow>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            renderSystemMessage()
        }
    </>;
}

export default ChatItem;
