import cls from "@/pages/Chat/feature/Modal/PredictionModal/style.module.scss";
import { Avatar, Image } from "antd-mobile";
import pic from "@/assets/game-pic/scratch off.png";
import dayjs from "dayjs";
import PredictionTable, { ColumnProps } from "@/pages/Chat/feature/Modal/PredictionModal/PredictionTable";
import { renderForecastResults, Reflex, DiceType } from "@chat/component";
import { EventResult, ForecastDataItem } from "@/store/slice/prediction";
import { useEffect, useMemo, useState } from "react";
import { getWinList } from "@/apis/room";
import _ from "lodash";
import { useRequest } from "ahooks";


type HistoryListProps = {
    detail: {
        winningResults: EventResult;
        forecastResults: EventResult;
        cycle: number,
        drawTime: number,
        forecastRate: number,
        gameType: string,
        groupId: string,
        issueNo: string,
        sourceId: number,
        status: number,
        forecastData: ForecastDataItem;
        gameName: string
    }
}
type HistoryItem = {
    issueNo: string,
    playerName: string,
    // * color 购买彩票的类型 1-数字 2-颜色 * dice 中奖购买结果
    selectType: number,
    // 购买的彩票号码，数字为0-9，颜色为r,g,v
    selectValue: string,
    userAvatar: string,
    userId: number,
    winAmount: number
}

export default function HistoryList(props: HistoryListProps) {
    const { winningResults, forecastResults, status, forecastData, issueNo, gameType, drawTime } = props.detail;
    const getState = () => {
        switch (status) {
            case 1:
                return <div className={ cls.hit }>Hit</div>;
            case 2:
                return <div className={ cls.missed }>Missed</div>;
            default:
                return <div className={ cls.missed }>-</div>;
        }
    };
    const columns: ColumnProps[] = useMemo(() => {
        const cols: ColumnProps[] = [
            {
                title: <div className={ cls.issue }>USER NAME</div>,
                dataIndex: "playerName",
                key: "playerName",
                render(val, record) {
                    return <div className={ cls.issue } style={{display: "flex", alignItems:"center", gap:4}}>
                        <Avatar src={ record.userAvatar} className={cls.avatar}/>
                        { val }
                    </div>;
                }
            },
            {
                title: <>
                    <div>WINNING</div>
                    <div>AMOUNT</div>
                </>,
                dataIndex: "winAmount",
                key: "winAmount",
                render(val) {
                    return <div className={ cls.price }>{ "₹" + val || 0 }</div>;
                }
            },
            {
                title: <>
                    <div>WINNING</div>
                    <div>PRIZES</div>
                </>,
                dataIndex: "selectValue",
                key: "selectValue",
                render(val, record) {
                    const render = () => {
                        switch (gameType) {
                            case "color":
                                return <Reflex.Color type={ _.toString(val) }/>;
                            case "dice":
                                const filterType = ["sum_odd", "sum_even", "sum_big", "sum_small"];
                                let value = "Digit";
                                if (filterType.includes(record.selectType)) {
                                    value = _.camelCase(record.selectType).substring(3);
                                }
                                return <Reflex.Dice type={ DiceType.SUM }
                                                    value={ value }></Reflex.Dice>;
                            case "3digit":
                                return <Reflex.Digit type={ _.toString(val) }></Reflex.Digit>;
                        }
                    };
                    // 根据不同的类型，渲染不同的类型结果
                    return <div style={ { display: "flex", justifyContent: "center" } }>
                        {
                            render()
                        }
                    </div>;
                }
            }
        ];
        return cols;
    }, [gameType]);
    const [dataSource, setDataSource] = useState<HistoryItem[]>([]);
    const { run: fetchList, loading } = useRequest(getWinList, {
        manual: true,
        onSuccess(res) {
            if (res.code === 0) {
                setDataSource(res.data);
            }
        }
    });
    useEffect(() => {
        // 获取历史消息
        fetchList({
            issueNo: issueNo,
            gameType: gameType
        });
    }, [issueNo, gameType]);
    return <>
        <div className={ cls.container }>
            <div className={ cls.detail }>
                <Image src={ pic } className={ cls.pic }/>
                <div className={ cls.detail__info }>
                    <div className={ cls.name }>{ forecastData.gameName }</div>
                    <div className={ cls.id }>{ issueNo }</div>
                </div>
                <div className={ cls.detail__time }>
                    <div className={ cls.drawText }>Draw time</div>
                    <div className={ cls.drawTime }>{ dayjs(drawTime).format("DD-MM-YY hh:mm:ss") }</div>
                </div>
            </div>
            <div className={ cls.line }></div>
            <div className={ cls.analyze }>
                <div className={ cls.analyze_item }>
                    <div className={ cls.analyze_item_label }>Forecast Results</div>
                    <div className={ cls.analyze_item_value }
                         style={ { display: "flex", gap: 1 } }>{ renderForecastResults({
                        result: forecastResults,
                        gameType: forecastData.gameType
                    }) }</div>
                </div>
                <div className={ cls.analyze_item }>
                    <div className={ cls.analyze_item_label }>Winning Results</div>
                    <div className={ cls.analyze_item_value }
                         style={ { textAlign: "center", display: "flex", gap: 1 } }>{ renderForecastResults({
                        result: winningResults,
                        gameType: forecastData.gameType
                    }) }</div>
                </div>
                <div className={ cls.analyze_item }>
                    <div className={ cls.analyze_item_label }>Status</div>
                    <div className={ cls.analyze_item_value }
                         style={ { textAlign: "end" } }>{ getState() }
                    </div>
                </div>
            </div>
        </div>
        <PredictionTable loading={ loading } dataSource={ dataSource } columns={ columns }/>
    </>;
}
